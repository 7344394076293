<table class="table animated fadeIn">

  <thead>
    <tr class="header-reut">
      <th class="header-reut table-head" *ngIf="showAvatarColumn"> <i class="fa fa-image"></i></th>
      <th class="header-reut table-head" *ngFor="let header of headers" (click)="sortData(header)">
        {{ header }}
        <span *ngIf="sortColumn === header">
          <i [ngClass]="sortDirection ? 'fa fa-sort-alpha-asc' : 'fa fa-sort-alpha-desc'"></i>
        </span>
      </th>
      <th class="header-reut table-head" *ngIf="showStatusColumn">Estado</th>
      <th class="header-reut table-head item-options" *ngIf="showActionsColumn">Opciones</th>
    </tr>
  </thead>
  <tbody>
    <tr class="item_hover items-reut" *ngFor="let row of data">

      <td class="items-reut table-item " *ngIf="row.tabAvatar">
        <img [src]="row.tabAvatar" class="img-branch" width="40" height="40">
      </td>

      <td class="items-reut table-item" *ngFor="let header of headers">{{ row[header] }}</td>

      <td class="items-reut table-item" *ngIf="row.tabStatusColor || row.tabStatus">
        <b [style.background]="row.tabStatusColor" class="label"> {{ row.tabStatus }}</b>
      </td>

      <td class="items-reut table-item" *ngIf="row.tabActions && row.tabActions.length > 0">
        <custom-buttons
        *ngFor="let action of row.tabActions"
          (click)="executeAction(action, row)"
          [inputBackgroundColor]="action.color"
          [disabled]="action.disabled"
          [textSize]="action.textSize"
          [text]="action.label"
          [title]="action.title"
          [icon]="action.icon"
          [rounded]="true"
          textColor="#fff"
          width="43px"
          type="button">
        </custom-buttons>
      </td>

    </tr>
  </tbody>
</table>
