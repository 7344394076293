<div *ngIf="caso === 'serviceUno'" (click)="onEmitDataSubs(subService)" id="card-1" class="card"
    [title]="subService.subService.subserviceName">
    <div class="avatar">
        <img [src]="subServiceImg" [alt]="subService?.subService?.subserviceName || 'Default Image'" />

    </div>
    <div class="header">
        <h2>{{subService.subService.subserviceName }}</h2>
        <div class="speciality">{{ subService.subService.speciality.specialityName}}</div>
        <div class="company">
            <p>{{ subService.company.companySocialProfile.name }}</p>
        </div>
    </div>

    <hr class="style4">


    <div class="desc">
        <div class="section-price">
            <span>Precio Normal</span>
            <span class="tachado">{{ subService.subService.price | currencyFormat }}</span>
        </div>
        <div class="section-price">
            <span>Descuento</span>
            <span> {{subService.subService.discountedPrice | percentage : subService.subService.price }}%</span>
        </div>
        <div class="section-price-oferta">
            <span>Precio Oferta</span>
            <span>{{ subService.subService.discountedPrice | currencyFormat }}</span>
        </div>
    </div>
</div>

<div *ngIf="caso === 'serviceDos'" (click)="changedNewService(idCity, idService)" id="card-1" class="card"
    [title]="service.subserviceName">
    <div class="avatar">
        <img [src]="service.subserviceImages[0].path || subServiceImgDefault" [alt]="service.subserviceName" />

    </div>
    <div class="header">
        <h2>{{service.subserviceName }}</h2>
        <div class="speciality">{{ service.speciality.specialityName}}</div>
        <div class="company"> </div>
    </div>


    <hr class="style4">


    <div class="desc">
        <div class="section-price">
            <span>Precio Normal</span>
            <span class="tachado">{{ service.price | currencyFormat }}</span>
        </div>
        <div class="section-price">
            <span>Descuento</span>
            <span> {{service.discountedPrice | percentage : service.price }}%</span>
        </div>
        <div class="section-price-oferta">
            <span>Precio Oferta</span>
            <span>{{ service.discountedPrice | currencyFormat }}</span>
        </div>
    </div>
</div>

<div *ngIf="caso === 'doctors'" id="card-1" class="card" [title]="doctors.fullName">
    <div class="avatar">
        <img [src]="doctors.avatar" [alt]="doctors.fullName" />
    </div>
    <div class="header">
        <h2>{{ doctors.fullName}}</h2>
        <div *ngIf="doctors.specialities && doctors.specialities[0].specialityName" class="speciality">{{
            doctors.specialities[0].specialityName }}</div>
        <div class="profesional"> Profesional </div>
    </div>
    <div class="body-doctor">
        <p class="title-body">Social</p>
        <div *ngIf="doctors.soccialAccounts && doctors.soccialAccounts.length > 0" class="socials">
            <div *ngFor="let item of doctors.soccialAccounts">
                <a [href]="item.url" target="_blank">
                    <i [class]="item.icon"> </i>
                </a>
            </div>
        </div>
        <p class="no-registra" *ngIf="!doctors.soccialAccounts || doctors.soccialAccounts.length === 0 ">No Registra</p>
    </div>
</div>

<div *ngIf="caso === 'familyGroup'" id="card-1" class="card" [title]="infoFamilyGroup.name">
    <div class="avatar">
        <img [src]="infoFamilyGroup.avatar" [alt]="infoFamilyGroup.name" />
    </div>
    <div class="container text-center mt-4">
        <h3 class="m-b-10 text_ellipsis" [title]="infoFamilyGroup.name">{{infoFamilyGroup.name}}</h3>
        <h6 class="text-muted">Integrantes: <strong>{{infoFamilyGroup.numberOfBeneficiaries}}</strong></h6>
        <h6 class="text-muted">Mascotas: <strong>{{infoFamilyGroup.numberOfPets}}</strong></h6>
    </div>
    <div class="actions action-button">
        <custom-buttons *ngIf="infoFamilyGroup.isLeader" class="button-data" title="Ver" inputBackgroundColor="primary"
            text="Ver" type="button" (click)="actionButton(infoFamilyGroup,true)">
        </custom-buttons>
        <custom-buttons *ngIf="!infoFamilyGroup.isLeader" class="button-data" title="Ver" inputBackgroundColor="primary"
            text="Ver" type="button" (click)="actionButton(infoFamilyGroup, false)">
        </custom-buttons>
        <custom-buttons *ngIf="infoFamilyGroup.isLeader" class="button-data" title="Editar"
            inputBackgroundColor="secondary" text="Editar" type="button" (click)="actionButtonEdit(infoFamilyGroup)">
        </custom-buttons>
    </div>
</div>

<div *ngIf="caso === 'familyGroupPet'" id="card-1" class="card" [ngStyle]="{ 'height': getHeight() }" [title]="infoPet.petName" (click)="selectPet(infoPet)">
    <div class="avatar">
        <img [src]="infoPet.avatar" [alt]="infoPet.petName" />
    </div>
    <div class="container text-center mt-4">
        <h3 class="m-b-10 text_ellipsis" [title]="infoPet.name">{{infoPet.petName}}</h3>
        <h6 class="text-muted">Especie: <strong>{{infoPet.species || "No registra"}}</strong></h6>
        <h6 class="text-muted">Raza: <strong>{{infoPet.breed || "No registra"}}</strong></h6>
        <h6 class="text-muted">Color: <strong>{{infoPet.color || "No registra"}}</strong></h6>
        <h6 class="text-muted">Genero: <strong>{{infoPet.gender ==="Male"? "Macho":"Hembra" || "No registra"}}</strong></h6>
        <h6 class="text-muted">Nacimiento: <strong>{{infoPet.birth_date.split("T")[0] || "No registra"}}</strong></h6>
        <h6 class="text-muted">Esterelizado: <strong>{{infoPet.sterilized === true? "Si":"No" || "No registra"}}</strong></h6>
    </div>
    <div *ngIf="viewOptions" class="action action-button1">
        <custom-buttons class="button-data" title="Editar" inputBackgroundColor="primary"
            text="Editar" type="button" (click)="editPetAction.emit(infoPet)">
        </custom-buttons>
        <custom-buttons class="button-data" title="Eliminar" inputBackgroundColor="danger"
            text="Eliminar" type="button" (click)="deletePetAction.emit(infoPet)">
        </custom-buttons>
    </div>
</div>