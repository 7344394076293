import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdvisorService } from '../roles/advisor/services/advisor.service';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from "../shared-components/shared-components.module";
@Component({
  selector: 'app-short',
  templateUrl: './short.component.html',
  styleUrls: ['./short.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedComponentsModule]
})
export class ShortComponent {
  urlParam!: string;
  condition: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private advisor: AdvisorService,
  ) { }

  ngOnInit(): void {
    this.urlParam = this.route.snapshot.paramMap.get('url') || '';
    this.route.params.subscribe((params) => {
      this.urlParam = params['url'];
    });
    this.getURL();
  }

  /**
   * Obtiene la URL original a partir de una URL acortada y redirige al usuario.
   * @returns {Promise<void>} No retorna un valor explícito.
   */
  async getURL(): Promise<void> {
    if (!this.urlParam || typeof this.urlParam !== 'string' || this.urlParam.trim() === '') {
      this.condition = true;
      return;
    }
    const shortenedUrl = `https://www.descuentosmedicos.co/short/${this.urlParam}`;
    try {
      const res = await this.advisor.searchurl({ shortenedUrl });
      const urlOrigin = res?.body?.originalUrl;

      if (!urlOrigin) {
        throw new Error('No se encontró la URL original.');
      }

      window.location.href = urlOrigin;
    } catch (error: any) {
      this.condition = true;
    }
  }

}
