<div [id]="idContainer" class="row phones-container">
  <div *ngIf="txtTitle.length>0" class="col-12 m-t-30 text-center">
    <h4 class="mb-4 font_weight">{{txtTitle}}</h4>
  </div>
  <div [class]="classCol">
    <div class="card">
      <div class="card-body">
        <div class="card_title_phone">
          <h4 class="card-title text-center font_weight">Teléfonos Moviles</h4>
          <i class="fa fa-question icon__helper ml-2" [title]="helperMovil"></i>
        </div>
        <div class="row">
          <form ngNativeValidate class="form-material m-t-15 col-md-6 col-sm-5"
            style="border-right: 1px solid rgb(211, 211, 211);">
            <div class="row">
              <div class="col-12">
                <input class="form-control form-control-sm cell_input form_border mt-2" id="idCellPhone" type="text"
                  name="phone" [(ngModel)]="movilForm.phone" numbersOnly phoneMask placeholder="Numero de Telefono">
                <span *ngIf="movilError.length>0" class="text-danger animated fadeIn">{{movilError}}</span>
              </div>


              <div class="col-12 mt-3">
                <h6 class="text-center font_weight">Opciones del Movil</h6>
                <div class="check_col mt-2">
                  <input class="chk-col-teal" type="checkbox" [id]="'md_checkbox_1'" name="llamadas"
                    [(ngModel)]="movilForm.llamadas" checked />
                  <label class="check_col_font text-sm" id="idLabelCheck_l" [for]="'md_checkbox_1'">
                    <i class="mdi mdi-phone icon_phone tel_color"></i>
                    Es para Llamadas?
                  </label>
                </div>
              </div>


              <!-- <div class="col-12">
                                <div class="check_col mt-2 ">
                                    <input
                                        class="chk-col-teal"
                                        type="checkbox"
                                        [id]="'md_checkbox_2'"
                                        name="whatsapp"
                                        [(ngModel)]="movilForm.whatsapp"
                                         />
                                    <label
                                        class="check_col_font text-sm"
                                        id="idLabelCheck_w"
                                        [for]="'md_checkbox_2'">
                                        <i class="mdi mdi-whatsapp icon_phone whatsapp_color"></i>
                                        Es para Whatsapp?
                                    </label>
                                </div>
                                <span *ngIf="movilSelectError.length>0" class="text-danger animated fadeIn">{{movilSelectError}}</span>
                            </div> -->



            </div>
            <div class="row btn_container">
              <div class="btn_opcion text-center animated fadeIn" *ngIf="!movilSelected">
                <custom-buttons text="Agregar" title="permite agregar" textColor="#fff" icon="ti-plus ml-2"
                   (click)="registrarMovil(movilForm)" type="button" />

              </div>

              <div class="btn_opcion animated fadeIn" *ngIf="movilSelected">


                <custom-buttons class="col-6 btn-sm mt-2" text="Actualizar" textSize="1em" title="permite actualizar" [inputBackgroundColor]="'secondary'"
                  textColor="#fff" icon="fa fa-edit"  (click)="updateMovil()" type="button" />

                <custom-buttons class="col-6 btn-sm mt-2" textSize="1em" text="Cancelar" title="permite cancelar" [inputBackgroundColor]="'danger'"
                  textColor="#fff" backgroundColor="#e74d3ce6" icon="fa fa-window-close"  (click)="cancelUpdateMovil()"
                  type="button" />

              </div>
            </div>

          </form>

          <div class="col-md-6 col-sm-7 mt-2">
            <h6 class="text-center font_weight">Tus Moviles registrados</h6>
            <ul class="feeds mt-3 phones_list box bg-light-inverse">

              <ng-container *ngFor="let cellPhone of cellPhones">

                <li *ngIf="cellPhone.movil" [class.selected]="cellPhone === movilSelected">
                  <span>
                    <i *ngIf="cellPhone.llamadas" class="mdi mdi-phone icon_phone tel_color"></i>
                    <i *ngIf="cellPhone.whatsapp" class="mdi mdi-whatsapp icon_phone whatsapp_color icon_up"></i>
                  </span>

                  <span class="text-left">
                    {{cellPhone.phone}}
                  </span>

                  <div class="actions">
                    <i class="fa fa-edit update" title="Actualizar" (click)="selectMovil(cellPhone)"></i>
                    <i class="fa fa-trash delete" title="Eliminar" (click)="deletePhone(cellPhone)"></i>
                  </div>
                </li>

              </ng-container>

              <div class="no_registrados" *ngIf="!hayMovilesRegistrados">
                No hay Teléfonos Moviles registrados
              </div>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div [class]="classCol">
    <div class="card">
      <div class="card-body">
        <div class="card_title_phone">
          <h4 class="card-title text-center font_weight">Teléfonos Fijos</h4>
          <i class="fa fa-question icon__helper ml-2" [title]="helperFijo"></i>
        </div>
        <div class="row">
          <form ngNativeValidate class="form-material m-t-15 col-md-6 col-sm-5"
            style="border-right: 1px solid rgb(211, 211, 211);">
            <div class="row">
              <div class="form-group col-12">
                <h6 class="text-center font_weight">Prefijo del Teléfono</h6>
                <select class="form-control form-control-sm form_border" id="idPrefixFijo" name="prefix"
                  [(ngModel)]="fijoForm.prefix" (change)="cambioSelect($event)">
                  <option selected value="" disabled>Seleccione el Prefijo</option>
                  <option *ngFor="let prefijo of prefijos">
                    {{prefijo.prefix}}
                  </option>
                </select>
                <span *ngIf="prefixError.length>0" class="text-danger animated fadeIn">{{prefixError}}</span>
              </div>
              <div class="col-12">
                <input class="form-control form-control-sm cell_input form_border mb-2" id="idCellPhoneFijo" type="text"
                  name="phone" [(ngModel)]="fijoForm.phone" numbersOnly phoneMask placeholder="Numero de Telefono">
                <span *ngIf="fijoError.length>0" class="text-danger animated fadeIn">{{fijoError}}</span>
              </div>
            </div>

            <div class="row btn_container">
              <div class="btn_opcion text-center animated fadeIn" *ngIf="!fijoSelected">

                <custom-buttons text="Agregar" title="permite agregar" textColor="#fff" icon="ti-plus ml-2" width="100%"
                   (click)="registrarFijo(fijoForm)" type="button" />

              </div>

              <div class="btn_opcion animated fadeIn" *ngIf="fijoSelected">

                <custom-buttons class="col-6 btn-sm mt-2" text="Actualizar" textSize="1em" title="permite actualizar" [inputBackgroundColor]="'secondary'"
                  textColor="#fff" icon="fa fa-edit"  (click)="updateFijo()" type="button" />

                <custom-buttons class="col-6 btn-sm mt-2" textSize="1em" text="Cancelar" title="permite cancelar" [inputBackgroundColor]="'danger'"
                  textColor="#fff" backgroundColor="#e74d3ce6" icon="fa fa-window-close"  (click)="cancelUpdateFijo()"
                  type="button" />

              </div>
            </div>

          </form>

          <div class="col-md-6 col-sm-7 mt-2">
            <h6 class="text-center font_weight">Tus Fijos registrados</h6>
            <ul class="feeds mt-3 phones_list box bg-light-inverse">

              <ng-container *ngFor="let cellPhone of cellPhones">

                <li *ngIf="!cellPhone.movil" [class.selected]="cellPhone === fijoSelected">
                  <span>
                    <i *ngIf="cellPhone.llamadas" class="mdi mdi-phone icon_phone tel_color"></i>
                    <i *ngIf="cellPhone.whatsapp" class="mdi mdi-whatsapp icon_phone whatsapp_color icon_up"></i>
                  </span>

                  <span class="text-left">
                    {{cellPhone.prefix}}-{{cellPhone.phone}}
                  </span>

                  <div class="actions">
                    <i class="fa fa-edit update" title="Actualizar" (click)="selectFijo(cellPhone)"></i>
                    <i class="fa fa-trash delete" title="Eliminar" (click)="deletePhone(cellPhone)"></i>
                  </div>
                </li>

              </ng-container>

              <div class="no_registrados" *ngIf="!hayFijosRegistrados">
                No hay Teléfonos Fijos registrados
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
