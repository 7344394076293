<!-- 
<main class="container-main">
  <section class="term-and-conditions">
    <h2 class="mt-5">

    <ng-container *ngIf="showButton">
      <custom-buttons (click)="emitClicked()" class="" text="" textColor="#fff" type="button" icon="mdi mdi-arrow-left" [title]="''" textSize="20px"></custom-buttons>
    </ng-container>


      Terminos & Condiciones
    </h2>
    <p class="px-5">
      Para recibir descuentos debe estar inscrito y sus datos diligenciados al 100%, los descuentos deberá ser redimido a
      nuestros terceros para confirmar día fecha y hora de su requerimiento. No tenemos límite de descuentos por persona.
      El descuento se enviará vía e-mail, WhatsApp, mensaje de texto o en su defecto se le llamara personalmente para
      conocimiento del solicitante. Para preguntas o reclamos (PQRS) se debe ingresar a www.prevenirexpress.com en espacio
      PQRS o al correo  <span>{{correoCLiente}}</span>, se debe enviar un e-mail ha solicitado su requerimiento o comunicarse
      a los teléfonos (2) 7290038 o WhatsApp 310 4351937, 3172645522, o al correo <span>{{correoAdmin}}</span>.
  
      GRUPO PREVENIR EXPRESS Sas. Es un enlace entre el usuario y el tercero para que apliquen los descuentos del servicio
      y NO se hace responsable de incumplimientos, malos procedimientos pero estará muy atento a las sugerencia quejas y
      reclamos para dar un buen servicio.
    </p>
    <small class="px-5">*Los descuentos los aplica la entidad jurídica o natural de acuerdo a sus condiciones y
      restricciones.
    </small>
    <small class="px-5">*Aplican condiciones y restricciones a todos los descuentos.</small>
  </section>
  
  <section class="container-tab">
    <h2>
      Tratamiento de datos, privacidad y confidencialidad
    </h2>
    <app-tab-vertical-component [tabs]="tabsVertical"></app-tab-vertical-component>
  </section>
</main> 
-->

<app-landing-header class="header"></app-landing-header>
<main class="container_page">
  <div class="container_main">
    <h1>Política de uso, términos y condiciones</h1>
    <div class="indice">
      <h2>Términos y condiciones</h2>
      <ol class="indice_terms">
        <li (click)="scrollToSection(0)" [ngClass]="{'active': selectedIndex === 0}">Aceptación y alcance.</li>
        <li (click)="scrollToSection(1)" [ngClass]="{'active': selectedIndex === 2}">Tratamiento de datos, privacidad y
          confidencialidad.</li>
        <li (click)="scrollToSection(2)" [ngClass]="{'active': selectedIndex === 3}">Registro y uso de la plataforma.
        </li>
        <li (click)="scrollToSection(3)" [ngClass]="{'active': selectedIndex === 4}">Servicios ofrecidos.</li>
        <li (click)="scrollToSection(4)" [ngClass]="{'active': selectedIndex === 5}">Pago y facturación.</li>
        <li (click)="scrollToSection(5)" [ngClass]="{'active': selectedIndex === 6}">Publicidad, promociones y eventos.
        </li>
        <li (click)="scrollToSection(6)" [ngClass]="{'active': selectedIndex === 7}">Contenido generado por los
          usuarios.</li>
        <li (click)="scrollToSection(7)" [ngClass]="{'active': selectedIndex === 8}">Código de ética y responsabilidad
          del usuario.</li>
        <li (click)="scrollToSection(8)" [ngClass]="{'active': selectedIndex === 9}">Aplicación móvil, web y soporte
          técnico.</li>
        <li (click)="scrollToSection(9)" [ngClass]="{'active': selectedIndex === 10}">Transferencia de datos y cambios
          corporativos.</li>
        <li (click)="scrollToSection(10)" [ngClass]="{'active': selectedIndex === 11}">Legislación aplicable y
          resolución de conflictos.</li>
      </ol>
      <h2>Política de protección de datos</h2>
      <ol class=" indice_data_protection">
        <li (click)="scrollToSection(11)" [ngClass]="{'active': selectedIndex === 11}">Finalidad del tratamiento.</li>
        <li (click)="scrollToSection(12)" [ngClass]="{'active': selectedIndex === 12}">Categorías de datos recopilados.
        </li>
        <li (click)="scrollToSection(13)" [ngClass]="{'active': selectedIndex === 13}">Principios y bases legales.</li>
        <li (click)="scrollToSection(14)" [ngClass]="{'active': selectedIndex === 14}">Derechos de los usuarios.</li>
        <li (click)="scrollToSection(15)" [ngClass]="{'active': selectedIndex === 15}">Medidas de seguridad.</li>
        <li (click)="scrollToSection(16)" [ngClass]="{'active': selectedIndex === 16}">Trasnferencia de datos.</li>
        <li (click)="scrollToSection(17)" [ngClass]="{'active': selectedIndex === 17}">Periodo de retención y
          eliminación.</li>
        <li (click)="scrollToSection(18)" [ngClass]="{'active': selectedIndex === 18}">Modificaciones a la política de
          protección de datos.</li>
        <li (click)="scrollToSection(19)" [ngClass]="{'active': selectedIndex === 19}">Contacto y ejercicio de derechos.
        </li>
      </ol>
    </div>
    <div class="container_second">
      <section class="terms">
        <h2>Términos y condiciones</h2>
        <div class="container_content">
          <div id="section-0">
            <h3>1. Aceptación y alcance</h3>
            <h4>1.1 Aceptación de los Términos</h4>
            <p>Al registrarse y utilizar Descuentos Médicos App, unidad de negocio de <span>GRUPO PREVENIR EXPRESS
                SAS</span>, el usuario reconoce y acepta de forma expresa e inequívoca los presentes Términos y
              Condiciones, la Política de Tratamiento de Datos Personales y todas las demás normativas y disposiciones
              legales asociadas (incluyendo aquellas relacionadas con la protección de datos y el comercio electrónico
              en Colombia). Esta aceptación implica que el usuario se compromete a cumplir íntegramente con todas las
              disposiciones aquí establecidas, en conformidad con la legislación colombiana vigente, en particular la
              Ley 1581 de 2012. Además, el uso continuado de la plataforma constituirá una aceptación tácita y
              permanente de estos términos, obligando al usuario a respetarlos en su totalidad.</p>
            <h4>1.2 Modificación de los Términos</h4>
            <p>Descuentos Médicos App, como unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>, se reserva el
              derecho de modificar, actualizar o complementar estos Términos y Condiciones en cualquier momento. En caso
              de realizar cambios significativos que afecten los derechos u obligaciones de los usuarios, se
              implementará el siguiente procedimiento:</p>
            <ul>
              <li>Publicación en la Plataforma:
                <br>
                <p>Las modificaciones se publicarán en un área visible de la plataforma y en la sección correspondiente
                  del sitio web oficial.</p>
              </li>
              <li>Notificación Electrónica:
                <br>
                <p>Se enviará una notificación a través del correo electrónico registrado, detallando el alcance de los
                  cambios y proporcionando instrucciones para su revisión.</p>
              </li>
              <li>Periodo de Aceptación:
                <br>
                <p>Las modificaciones entrarán en vigor a partir de la fecha de publicación, salvo que se indique
                  expresamente lo contrario, otorgando un "período razonable" (por ejemplo, 15 días hábiles) para que el
                  usuario revise y acepte las nuevas condiciones. El uso continuado de la plataforma después de dicho
                  período implicará la aceptación de las modificaciones.</p>
              </li>
            </ul>
            <h4>1.3 Ámbito de Aplicación</h4>
            <p>Estos Términos y Condiciones se aplican a todas las personas que accedan, utilicen y se registren en
              Descuentos Médicos App. Esto incluye:</p>
            <ul>
              <li>Usuarios:
                <br>
                <p>Pacientes, médicos, veterinarios y aliados comerciales.</p>
              </li>
              <li>Ámbito Geográfico:
                <br>
                <p>La plataforma opera exclusivamente en Colombia.</p>
              </li>
              <li>Marco Legal:
                <br>
                <p>El uso de la plataforma se rige por la legislación colombiana vigente, en especial la Ley 1581 de
                  2012, el Código de Comercio, el Estatuto del Consumidor (Ley 1480 de 2011) y demás normativas
                  aplicables en materia de protección de datos y comercio electrónico.</p>
              </li>
            </ul>
            <h4>1.4 Vigencia y Terminación</h4>
            <ul>
              <li>Vigencia:
                <br>
                <p>La aceptación de estos Términos y Condiciones entrará en vigor en el momento en que el usuario se
                  registre o utilice la plataforma, y se mantendrá vigente mientras el usuario haga uso de Descuentos
                  Médicos App. Se entenderá que el uso continuado de la plataforma implica la aceptación tácita de los
                  términos, incluso cuando se realicen modificaciones.</p>
              </li>
              <li>Terminación y Renovación:
                <br>
                <ul>
                  <li>Por parte del usuario:
                    <br>
                    <p>El usuario puede dar por terminada su relación con la plataforma en cualquier momento, mediante
                      la eliminación de su cuenta, quedando obligado únicamente por las obligaciones asumidas antes de
                      la terminación. En caso de tener suscripciones activas, el usuario deberá cumplir con los términos
                      de cancelación aplicables antes de finalizar su relación. </p>
                  </li>
                  <li>Por parte de la plataforma:
                    <br>
                    <p>Descuentos Médicos App se reserva el derecho de suspender o finalizar unilateralmente el acceso a
                      la plataforma en caso de incumplimiento de estos Términos, uso fraudulento o cualquier otra
                      violación de las normativas aplicables, sin necesidad de previo aviso. </p>
                  </li>
                  <li>Renovación Tácita:
                    <br>
                    <p>La relación contractual se renovará de manera tácita mientras el usuario continúe utilizando la
                      plataforma, a menos que alguna de las partes notifique de manera expresa su intención de terminar
                      la relación conforme a los procedimientos establecidos.</p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <hr>
          <div id="section-1">
            <h3>2. Tratamiento de Datos, Privacidad y Confidencialidad</h3>
            <h4>2.1 Protección de Datos Personales</h4>
            <p>Descuentos Médicos App se compromete a cumplir con la Ley 1581 de 2012 y normativas relacionadas en
              Colombia. La recopilación, almacenamiento, tratamiento y uso de la información personal se realizará de
              manera lícita, transparente y segura, implementando medidas técnicas y organizativas que impidan el acceso
              no autorizado, divulgación, alteración o destrucción de los datos.</p>
            <h4>2.2 Uso de Cookies y Tecnologías de Seguimiento</h4>
            <p>La plataforma utiliza cookies y tecnologías de seguimiento para:</p>
            <ul>
              <li>Mejorar la experiencia del usuario:
                <br>
                <p>Permiten personalizar el contenido, recordar preferencias y optimizar la navegación.</p>
              </li>
              <li>Optimizar el rendimiento:
                <br>
                <p>Recopilan datos sobre el uso de la plataforma para identificar áreas de mejora y optimizar tiempos de
                  carga.</p>
              </li>
              <li>Análisis y estadísticas:
                <br>
                <p>Facilitan la obtención de métricas sobre el comportamiento de los usuarios para mejorar el servicio.
                </p>
              </li>
              <li>Publicidad y promociones:
                <br>
                <p>Se utilizan para ofrecer contenido publicitario personalizado, respetando siempre las preferencias y
                  la privacidad del usuario.</p>
              </li>
            </ul>
            <p>El usuario puede configurar o rechazar el uso de cookies sin que ello afecte el acceso a los servicios
              básicos de la plataforma.</p>
            <h4>2.3 Comunicaciones Electrónicas</h4>
            <ul>
              <li>Medios de Comunicación:
                <br>
                <p>La plataforma se comunicará con el usuario a través de correos electrónicos, mensajes SMS,
                  notificaciones push y otros medios digitales, enviando mensajes de forma no intrusiva y conforme a las
                  preferencias establecidas.</p>
              </li>
              <li>Consentimiento para Recibir Emails:
                <br>
                <p>Al registrarse, el usuario autoriza el envío de correos electrónicos con información relevante,
                  actualizaciones, promociones y novedades.</p>
              </li>
              <li>Notificaciones:
                <br>
                <p>Se enviarán notificaciones a través de la app y otros canales. Aquellas relacionadas con la gestión
                  de citas y recordatorios son obligatorias para el funcionamiento correcto del servicio.</p>
              </li>
              <li>Opción de Darse de Baja:
                <br>
                <p>El usuario puede modificar sus preferencias de comunicación o darse de baja de mensajes no
                  obligatorios siguiendo las instrucciones proporcionadas.</p>
              </li>
            </ul>
            <h4>2.4 Medidas de Seguridad y Almacenamiento</h4>
            <p>Para garantizar la integridad, confidencialidad y disponibilidad de los datos, se implementan las
              siguientes medidas:</p>
            <ul>
              <li>Cifrado de Datos:
                <br>
                <p>Uso de cifrado de extremo a extremo (AES-256, TLS 1.2 o superior) para proteger datos en tránsito y
                  en reposo.</p>
              </li>
              <li>Control de Accesos:
                <br>
                <p>Autenticación multifactor (MFA) y gestión de permisos basada en roles (RBAC) para restringir el
                  acceso a información sensible.</p>
              </li>
              <li>Almacenamiento Seguro:
                <br>
                <p>Los datos se alojan en servidores seguros con respaldos periódicos para prevenir pérdidas</p>
              </li>
              <li>Monitoreo y Detección de Amenazas:
                <br>
                <p>Sistemas IDS/IPS y análisis de comportamiento para detectar y mitigar amenazas en tiempo real.</p>
              </li>
              <li>Pruebas de Penetración:
                <br>
                <p>Ejecución de pentesting de forma regular para identificar y corregir vulnerabilidades</p>
              </li>
              <li>Registro y Auditoría:
                <br>
                <p>Mantenimiento de registros detallados de accesos y modificaciones para garantizar trazabilidad.</p>
              </li>
              <li>Actualizaciones y Parches de Seguridad:
                <br>
                <p>Aplicación regular de actualizaciones para prevenir vulnerabilidades.</p>
              </li>
              <li>Política de Respaldo y Recuperación:
                <br>
                <p>Respaldos periódicos y pruebas de recuperación para asegurar la disponibilidad de los datos.
                </p>
              </li>
              <li>Protección contra Malware y Ataques:
                <br>
                <p>Implementación de firewalls, antivirus y herramientas anti-malware para prevenir ataques</p>
              </li>
            </ul>
            <h4>2.5 Derechos de los Usuarios</h4>
            <p>Los usuarios tienen derecho a:</p>
            <ul>
              <li>Acceder: Solicitar un reporte detallado de los datos personales tratados.</li>
              <li>Rectificar: Solicitar la corrección de datos inexactos o incompletos.</li>
              <li>Cancelar/Eliminar: Pedir la eliminación de sus datos cuando ya no sean necesarios.</li>
              <li>Oponerse o Limitar: Oponerse al tratamiento de sus datos para fines específicos.</li>
            </ul>
            <p>Procedimiento para Ejercer Estos Derechos:</p>
            <ul>
              <li>Enviar una solicitud formal a través del formulario de contacto o al correo designado, incluyendo
                identificación y descripción clara del derecho a ejercer.</li>
              <li>La plataforma responderá en un plazo máximo de 15 días hábiles y, en caso de proceder, realizará las
                modificaciones pertinentes.</li>
              <li>Si la respuesta es insatisfactoria, el usuario podrá presentar un reclamo ante la Superintendencia de
                Industria y Comercio</li>
            </ul>
            <h4>2.6 Transferencia de Datos a Terceros</h4>
            <p>Condiciones para compartir datos:</p>
            <ul>
              <li>Se comparten datos únicamente cuando sea necesario para la prestación de servicios o para cumplir con
                obligaciones legales y contractuales.</li>
              <li>La información puede ser compartida con proveedores, aliados comerciales o terceros que actúen en
                nombre de la plataforma, siempre bajo estrictas medidas de seguridad y confidencialidad.</li>
              <li>La transferencia se realiza con fines específicos y, en la mayoría de los casos, con el consentimiento
                previo del usuario, salvo cuando la ley exija lo contrario.</li>
            </ul>
            <p>Transferencias internacionales y cambios corporativos:</p>
            <ul>
              <li>En transferencias a terceros fuera de Colombia, se garantizarán salvaguardas que mantengan el mismo
                nivel de protección.</li>
              <li>En casos de fusión, adquisición, escisión o reestructuración de <span>GRUPO PREVENIR EXPRESS
                  SAS</span>, los datos podrán ser transferidos a la entidad sucesora, siempre que cumpla con las mismas
                obligaciones de protección.</li>
              <li>Los usuarios serán notificados con antelación de cualquier cambio sustancial en la administración de
                la información.</li>
            </ul>
            <h4>2.7 Periodo de Retención y Eliminación</h4>
            <p>Duración del Almacenamiento:</p>
            <ul>
              <li>Los datos se conservarán durante el tiempo necesario para cumplir con las finalidades para las cuales
                fueron recolectados (gestión de citas, comunicaciones, análisis, etc.).</li>
              <li>También se almacenarán durante el período requerido para cumplir con obligaciones legales o
                contractuales en Colombia.</li>
            </ul>
            <p>Procedimientos de Eliminación o Anonimización:</p>
            <ul>
              <li>Una vez finalizado el período de retención o cuando los datos ya no sean necesarios, se procederá a su
                eliminación segura mediante protocolos que aseguren que la información no pueda ser recuperada.</li>
              <li>Alternativamente, se optará por la anonimización, desvinculando irreversiblemente los datos de la
                identidad del usuario para proteger su privacidad.</li>
            </ul>
            <h4>2.8 Modificaciones a la Política de Protección de Datos</h4>
            <ul>
              <li>Actualizaciones:
                <br>
                <p>Descuentos Médicos App se reserva el derecho de modificar esta política en función de cambios en la
                  legislación, tecnología o prácticas internas.
                </p>
              </li>
              <li>Notificación:
                <br>
                <p>Cualquier cambio se comunicará a los usuarios a través de los canales oficiales (correo electrónico,
                  notificaciones en la plataforma, etc.) con la debida antelación.
                </p>
              </li>
              <li>Consentimiento:
                <br>
                <p>Si los cambios afectan significativamente el tratamiento de datos, se solicitará el consentimiento
                  expreso del usuario antes de que entren en vigor.
                </p>
              </li>
            </ul>
            <h4>2.9 Contacto y Ejercicio de Derechos</h4>
            <p>Para consultas, dudas o para ejercer los derechos establecidos en esta política, los usuarios pueden
              comunicarse a través del formulario de contacto disponible en la plataforma o mediante el correo
              electrónico designado para atención al usuario</p>
          </div>
          <hr>
          <div id="section-2">
            <h3>3. Registro y uso de la plataforma</h3>
            <p>Esta sección describe los requisitos para el registro, las medidas de seguridad, las condiciones de uso
              autorizadas y los procedimientos aplicables en caso de suspensión o eliminación de cuentas en Descuentos
              Médicos App.</p>

            <h4>3.1 Requisitos de Registro y Validación de Datos</h4>
            <p>Para acceder a Descuentos Médicos App, es obligatorio que el usuario proporcione información precisa y
              actualizada durante el proceso de registro. Esto incluye, pero no se limita a:</p>
            <ul>
              <li>Datos Personales Básicos:
                <br>
                <p>Información como nombre completo, número de documento de identidad, fecha de nacimiento y dirección
                  de correo electrónico, que permita identificar al usuario y facilitar la comunicación.</p>
              </li>
              <li>Datos de Contacto:
                <br>
                <p>Número de teléfono móvil u otros medios de contacto, necesarios para la verificación de la cuenta y
                  el envío de notificaciones importantes.</p>
              </li>
              <li>Veracidad de la Información:
                <br>
                <p>El usuario se compromete a proporcionar datos exactos y verídicos. La detección de información falsa
                  o inexacta podrá dar lugar a la suspensión o eliminación inmediata de la cuenta.</p>
              </li>
              <li>Validación de Datos:
                <br>
                <p>Se podrán implementar mecanismos de verificación, como confirmación vía correo electrónico, mensajes
                  SMS o validaciones mediante terceros, para asegurar la autenticidad de los datos ingresados.</p>
              </li>
            </ul>

            <h4>3.2 Seguridad de la Cuenta y Credenciales</h4>
            <p>El usuario es responsable de mantener la confidencialidad y seguridad de su contraseña y demás datos de
              acceso. Esto implica:</p>
            <ul>
              <li>Gestión Segura de Credenciales:
                <br>
                <p>El usuario debe seleccionar contraseñas robustas y únicas, evitar compartirlas con terceros y
                  actualizarlas periódicamente para minimizar riesgos.</p>
              </li>
              <li>Responsabilidad Personal:
                <br>
                <p>Toda actividad realizada desde la cuenta registrada será responsabilidad exclusiva del usuario. En
                  caso de pérdida, robo o uso no autorizado de las credenciales, el usuario debe notificar de inmediato
                  a Descuentos Médicos App para que se adopten las medidas correspondientes.</p>
              </li>
              <li>Medidas de Protección Adicionales:
                <br>
                <p>La plataforma podrá implementar mecanismos adicionales de seguridad, como la autenticación
                  multifactor (MFA), para fortalecer el acceso y proteger la integridad de la información personal.</p>
              </li>
            </ul>

            <h4>3.3 Uso Autorizado y Conducta</h4>
            <p>El uso de Descuentos Médicos App debe realizarse de forma responsable y acorde a su finalidad. Para ello,
              se establecen las siguientes directrices:</p>
            <ul>
              <li>Actividades Permitidas:
                <ul>
                  <li>Utilizar la plataforma para gestionar y agendar citas médicas y veterinarias particulares.</li>
                  <li>Acceder a los servicios de descuentos y promociones de forma legal y transparente.</li>
                  <li>Participar en espacios de interacción, como foros o comentarios, siempre respetando las normas de
                    convivencia y las leyes aplicables.</li>
                </ul>
              </li>
              <li>Actividades Prohibidas:
                <ul>
                  <li>Fraude: Emplear acciones destinadas a engañar o defraudar el sistema, otros usuarios o terceros.
                  </li>
                  <li>Suplantación de Identidad: Utilizar datos o credenciales ajenas para acceder a la plataforma o
                    representar a otro usuario.</li>
                  <li>Uso Indebido: Emplear la plataforma para fines distintos a los establecidos en estos Términos,
                    afectando la integridad, seguridad o continuidad del servicio.</li>
                  <li>Manipulación de Datos: Intentar alterar, interceptar o interferir en la transmisión de datos o en
                    el funcionamiento de la plataforma.</li>
                  <li>Conducta Inadecuada: Publicar o difundir contenido ofensivo, ilegal, difamatorio o que vulnere los
                    derechos de terceros.</li>
                </ul>
              </li>
            </ul>

            <h4>3.4 Suspensión y Eliminación de Cuentas</h4>
            <p>Descuentos Médicos App se reserva el derecho de suspender o eliminar la cuenta de cualquier usuario que
              incurra en incumplimientos de estos Términos o realice conductas que puedan comprometer la seguridad y el
              funcionamiento de la plataforma. Esto incluye, pero no se limita a:</p>
            <ul>
              <li>Información Falsa o Inexacta:
                <br>
                <p>Suministrar datos incorrectos o fraudulentos durante el proceso de registro o en actualizaciones
                  posteriores.</p>
              </li>
              <li>Actividades Fraudulentas o Maliciosas:
                <br>
                <p>Realizar acciones que impliquen fraude, suplantación de identidad, manipulación de datos o intentos
                  de acceso no autorizado.</p>
              </li>
              <li>Incumplimiento de Normas de Uso:
                <br>
                <p>Participar en actividades prohibidas, como la publicación de contenido ofensivo, ilegal o
                  difamatorio, o utilizar la plataforma para fines distintos a los estipulados.</p>
              </li>
              <li>Inobservancia de Obligaciones Contractuales:
                <br>
                <p>No cumplir con las responsabilidades asumidas en el uso de la plataforma, como el incumplimiento en
                  el pago de tarifas u otras obligaciones contractuales.</p>
              </li>
              <li>Otras Conductas Inapropiadas:
                <br>
                <p>Cualquier acción que, a juicio de Descuentos Médicos App, pueda afectar la integridad, seguridad o
                  estabilidad del servicio.</p>
              </li>
            </ul>
            <p>Procedimiento para la Suspensión o Eliminación:</p>
            <ul>
              <li>Notificación:
                <br>
                <p>El usuario será informado a través de los medios de contacto registrados sobre la suspensión o
                  eliminación de su cuenta, con una descripción clara de las razones.</p>
              </li>
              <li>Revisión Interna:
                <br>
                <p>Se llevará a cabo una auditoría para verificar la conducta que motivó la medida. En casos
                  específicos, se podrá solicitar información adicional al usuario para esclarecer los hechos.</p>
              </li>
              <li>Derecho de Apelación:
                <br>
                <p>El usuario podrá presentar una apelación o solicitud de revisión a través de los canales de atención
                  designados. Si se comprueba que la medida fue injustificada, se revaluará la decisión adoptada.</p>
              </li>
            </ul>
            <p>La suspensión o eliminación de la cuenta se realizará sin perjuicio de las acciones legales que
              Descuentos Médicos App tenga derecho a ejercer, conforme a la legislación colombiana vigente.</p>
          </div>
          <hr>
          <div id="section-3">
            <h3>4. Servicios ofrecidos</h3>
            <p>Descuentos Médicos App, unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>, actúa como facilitador del acceso a descuentos exclusivos en servicios médicos y veterinarios, y permite la coordinación eficiente de citas. La plataforma opera como intermediario, conectando a los usuarios con proveedores de salud, quienes son responsables de la prestación directa de los servicios. A continuación, se detallan las características, condiciones y limitaciones del servicio.</p>
        
            <h4>4.1 Descripción de los Servicios</h4>
            <p>Descuentos Médicos App ofrece a sus usuarios:</p>
            <ul>
                <li>Descuentos en Servicios Médicos y Veterinarios:
                    <br>
                    <p>La plataforma permite beneficiarse de descuentos que oscilan entre el 10% y el 50% en consultas, procedimientos y servicios diagnósticos. Estos descuentos se aplican a servicios ofrecidos por una red de médicos y especialistas veterinarios debidamente acreditados, y están sujetos a las condiciones específicas de cada proveedor.</p>
                </li>
                <li>Agendamiento de Citas:
                    <br>
                    <p>La aplicación facilita la reserva, confirmación, reprogramación y cancelación de citas de forma ágil y en tiempo real, eliminando la necesidad de realizar llamadas telefónicas. Esto asegura que los usuarios puedan gestionar sus citas de manera eficiente.</p>
                </li>
                <li>Acceso a Tarifas Preferenciales:
                    <br>
                    <p>Gracias a acuerdos comerciales directos, la plataforma ofrece tarifas negociadas y condiciones exclusivas que no se encuentran disponibles en canales tradicionales.</p>
                </li>
                <li>Información y Transparencia:
                    <br>
                    <p>Todos los servicios, descuentos y promociones se presentan de manera clara y actualizada, garantizando la transparencia en la información ofrecida a los usuarios.</p>
                </li>
            </ul>
        
            <h4>4.2 Condiciones y Limitaciones del Servicio</h4>
            <p>Descuentos Médicos App actúa únicamente como intermediario y, en ese rol, establece lo siguiente:</p>
            <ul>
                <li>Alcance de los Servicios:
                    <ul>
                        <li>La plataforma facilita el acceso a descuentos y tarifas preferenciales en servicios médicos y veterinarios, permitiendo a los usuarios agendar citas en tiempo real.</li>
                        <li>Los servicios ofrecidos, incluyendo aquellos gestionados a través de convenios de colaboración, son de carácter particular y se gestionan directamente entre el usuario y el proveedor de salud.</li>
                        <li>Adicionalmente, se establecen convenios de colaboración con empresas de diversos sectores que, mediante un modelo de marca blanca en sus páginas web, ofrecen descuentos a sus clientes y empleados.</li>
                    </ul>
                </li>
                <li>Exclusiones:
                    <ul>
                        <li>Descuentos Médicos App no garantiza la disponibilidad continua de un servicio o descuento, ya que esto depende de la capacidad y disponibilidad del proveedor.</li>
                        <li>La plataforma y las empresas colaboradoras no asumen responsabilidad alguna por la calidad, puntualidad o resultados de los servicios prestados, siendo estos responsabilidad exclusiva del profesional o entidad que los ofrece.</li>
                        <li>En consecuencia, tanto la plataforma como las empresas asociadas quedan exoneradas de cualquier responsabilidad en la prestación directa de los servicios de salud.</li>
                    </ul>
                </li>
                <li>Limitaciones de Responsabilidad:
                    <ul>
                        <li>La responsabilidad de Descuentos Médicos App se limita a facilitar el acceso a la información y coordinar el agendamiento de citas.</li>
                        <li>En ningún caso la plataforma ni las empresas colaboradoras serán responsables por daños directos, indirectos, incidentales o consecuentes derivados del uso de los servicios o de cualquier interrupción en la información.</li>
                        <li>Se recomienda a los usuarios verificar la idoneidad y condiciones del servicio directamente con el proveedor antes de confirmar una cita.</li>
                    </ul>
                </li>
            </ul>
        
            <h4>4.3 Procedimientos de Agendamiento y Uso</h4>
            <p>El proceso para la reserva de citas y el uso de los servicios se ha diseñado para ser intuitivo, ágil y seguro:</p>
            <ul>
                <li>Acceso a la Plataforma:
                    <br>
                    <p>El usuario inicia sesión en Descuentos Médicos App a través de la aplicación móvil o del sitio web utilizando sus credenciales verificadas.</p>
                </li>
                <li>Selección del Servicio:
                    <br>
                    <p>Una vez autenticado, el usuario navega por el catálogo de servicios y elige la especialidad deseada (médica o veterinaria), revisando la información detallada sobre el profesional, incluyendo experiencia y condiciones de atención.</p>
                </li>
                <li>Visualización de Disponibilidad:
                    <br>
                    <p>La plataforma muestra la disponibilidad de citas en tiempo real, permitiendo al usuario seleccionar la fecha y hora que mejor se adapten a sus necesidades.</p>
                </li>
                <li>Reserva y Confirmación:
                    <br>
                    <p>Tras seleccionar el horario, el usuario confirma la reserva. La plataforma envía inmediatamente una confirmación a través de la aplicación y por correo electrónico, garantizando que el usuario reciba la notificación de la cita.</p>
                </li>
                <li>Recordatorios y Gestión de Citas:
                    <br>
                    <p>Se envían recordatorios automáticos previos a la cita para asegurar que el usuario esté informado y preparado. Además, la plataforma facilita la gestión de la cita, permitiendo cancelaciones o reprogramaciones directamente desde la aplicación.</p>
                </li>
                <li>Atención en Tiempo Real:
                    <br>
                    <p>La coordinación entre el usuario y el profesional se realiza de forma directa, asegurando que la atención se brinde puntualmente y conforme a los términos acordados.</p>
                </li>
            </ul>
        
            <h4>4.4 Responsabilidad en la Prestación del Servicio</h4>
            <ul>
                <li>Rol de los Profesionales:
                    <br>
                    <p>Los médicos, veterinarios y demás especialistas que ofrecen sus servicios a través de la plataforma son los únicos responsables de la atención brindada, incluyendo diagnósticos, tratamientos y resultados. Cada profesional actúa de forma independiente y conforme a los estándares de su especialidad.</p>
                </li>
                <li>Límites de la Responsabilidad de la Plataforma:
                    <ul>
                        <li>Descuentos Médicos App actúa únicamente como intermediario facilitador del acceso a descuentos y la coordinación de citas particulares, sin asumir responsabilidad directa sobre la calidad o disponibilidad de los servicios.</li>
                        <li>La plataforma no garantiza ni controla la prestación de los servicios ofrecidos a través de convenios de colaboración con empresas que operan bajo un modelo de marca blanca, por lo que la responsabilidad final recae en el proveedor de salud.</li>
                        <li>Se recomienda a los usuarios verificar las credenciales, experiencia y condiciones del servicio con el proveedor antes de confirmar una cita.</li>
                    </ul>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-4">
            <h3>5. Pago y facturación</h3>
            <h4>5.1 Métodos de Pago Aceptados</h4>
            <p>Descuentos Médicos App ofrece diversas opciones de pago para facilitar el acceso a sus servicios, asegurando transacciones seguras y eficientes. Entre los métodos de pago aceptados se incluyen:</p>
            <ul>
                <li>Tarjetas de Crédito y Débito:
                    <br>
                    <p>Se aceptan tarjetas de las principales entidades financieras, permitiendo pagos rápidos y seguros.</p>
                </li>
                <li>PSE (Pagos Seguros en Línea):
                    <br>
                    <p>Los usuarios pueden realizar pagos directos desde sus cuentas bancarias a través de la plataforma PSE, garantizando una transacción ágil y segura.</p>
                </li>
                <li>Transferencias Bancarias:
                    <br>
                    <p>Se permiten transferencias electrónicas a cuentas designadas por Descuentos Médicos App, siguiendo los protocolos de seguridad establecidos.</p>
                </li>
                <li>Financiación a través de plataformas de delevery:
                    <br>
                    <p>Se ofrece la opción de financiar el pago mediante la plataforma, facilitando el acceso a los servicios mediante condiciones de pago flexibles.</p>
                </li>
                <li>Plataformas y Pasarelas de Pago:
                    <br>
                    <p>La integración con reconocidas pasarelas de pago permite a los usuarios completar sus transacciones de forma segura y conforme a los estándares internacionales.</p>
                </li>
            </ul>
            <p>Estas opciones están diseñadas para proporcionar al usuario una experiencia de pago cómoda, segura y adaptada a sus necesidades, cumpliendo con las normativas vigentes y garantizando la protección de sus datos financieros.</p>
            <h3>Facturación, reembolsos y consecuencias del incumplimiento</h3>
            <h4>5.2 Condiciones de Facturación y Plazos de Pago</h4>
            <p>Procedimientos de Facturación:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> actúan únicamente como intermediarios y no son responsables de la facturación por consultas, tratamientos o procedimientos médicos o veterinarios agendados a través de la plataforma.</li>
                <li>La facturación es responsabilidad exclusiva del médico independiente, profesional de la salud, IPS o entidad prestadora del servicio, conforme a la normativa colombiana.</li>
                <li>En el caso de servicios ofrecidos mediante convenios de colaboración con empresas bajo un modelo de marca blanca, la facturación recae únicamente en la entidad prestadora del servicio o en el profesional de la salud correspondiente.</li>
            </ul>
            <p>Plazos de Pago:</p>
            <ul>
                <li>Los pagos por los servicios de salud deben efectuarse de acuerdo con las condiciones establecidas por cada profesional o entidad prestadora.</li>
                <li>Para servicios con pagos recurrentes o suscripciones, los usuarios serán notificados con antelación (por ejemplo, 15 días hábiles) sobre cualquier cambio en los montos o en las fechas de pago, utilizando los canales oficiales de comunicación.</li>
            </ul>
            <p>Revisión y Confirmación:</p>
            <ul>
                <li>Se recomienda que los usuarios verifiquen directamente con el prestador del servicio que la factura emitida refleje los montos y detalles correctos.</li>
                <li>En caso de dudas o discrepancias, los usuarios deberán contactar directamente a la entidad o profesional responsable de la facturación.</li>
            </ul>
            <p>Exoneración de Responsabilidad:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> no tienen responsabilidad en la emisión, cobro o contenido de las facturas generadas por los prestadores de servicios de salud.</li>
            </ul>
            <h4>5.3 Políticas de Reembolsos y Cancelaciones</h4>
            <p>Responsabilidad de la Facturación:</p>
            <ul>
                <li>Dado que Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> no prestan servicios médicos ni veterinarios, no son responsables de reembolsos o cancelaciones de citas, tratamientos o procedimientos. La facturación y, en consecuencia, la política de reembolso es responsabilidad exclusiva del médico, profesional de la salud, IPS o entidad prestadora del servicio, conforme a la normativa colombiana.</li>
            </ul>
            <p>Política Exclusiva del Prestador:</p>
            <ul>
                <li>Cada profesional o entidad prestadora del servicio establece su propia política de cancelación y reembolso, la cual regirá para las citas particulares o servicios contratados a través de la plataforma.</li>
            </ul>
            <p>Procedimiento para Reembolsos y Cancelaciones:</p>
            <ul>
                <li>Los usuarios deben comunicarse directamente con el prestador del servicio para gestionar cancelaciones o solicitar reembolsos, siguiendo los términos y condiciones establecidos por dicho prestador.</li>
                <li>En caso de que el pago se haya efectuado a través de la plataforma, cualquier solicitud de devolución deberá gestionarse directamente con el prestador responsable de la facturación.</li>
            </ul>
            <p>Exoneración de Responsabilidad:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> no intervienen ni se responsabilizan por disputas, reembolsos o modificaciones en las condiciones de prestación de los servicios de salud o veterinarios.</li>
            </ul>
            <h4>5.4 Consecuencias del Incumplimiento</h4>
            <p>Responsabilidad del Prestador:</p>
            <ul>
                <li>La facturación y el cobro de los servicios de salud o veterinarios son responsabilidad exclusiva del prestador. Por lo tanto, cualquier consecuencia derivada del incumplimiento en los pagos será determinada por el médico, profesional de la salud, IPS o entidad prestadora del servicio.</li>
            </ul>
            <p>Políticas de Mora e Intereses:</p>
            <ul>
                <li>Cada prestador de servicios de salud es responsable de establecer sus propias políticas de mora, intereses y otras consecuencias en caso de retrasos en el pago, lo que puede incluir la aplicación de recargos o la suspensión del servicio.</li>
            </ul>
            <p>Pagos Recurrentes y Suscripciones:</p>
            <ul>
                <li>Para servicios con pagos recurrentes o suscripciones, el usuario debe cumplir con los plazos de pago establecidos al momento de la contratación. El incumplimiento de estas obligaciones podrá resultar en la suspensión del acceso a los beneficios asociados.</li>
            </ul>
            <p>Restricción de Acceso:</p>
            <ul>
                <li>Descuentos Médicos App se reserva el derecho de restringir o suspender el acceso a la plataforma a aquellos usuarios que no cumplan con sus obligaciones de pago.</li>
            </ul>
            <p>Exoneración de Responsabilidad:</p>
            <ul>
                <li>Ni Descuentos Médicos App ni <span>GRUPO PREVENIR EXPRESS SAS</span> asumen responsabilidad por cobros, sanciones, intereses o cualquier otra acción tomada por los prestadores de servicios de salud en caso de incumplimiento de pago por parte del usuario.</li>
            </ul>
            <h4>5.5 Críticas y Sugerencias de Mejora Integradas</h4>
            <ul>
                <li>Claridad y Precisión: Se han reorganizado y simplificado los puntos para que cada apartado sea claro y directo. Se han especificado plazos de notificación y se ha enfatizado el rol de la plataforma como intermediario.</li>
                <li>Formato y Consistencia: Se ha utilizado un formato consistente con encabezados y viñetas para facilitar la lectura y referencia rápida.</li>
                <li>Notificación y Procedimientos: Se ha añadido información específica sobre el aviso previo en servicios recurrentes y se ha integrado la exoneración de responsabilidad en un solo bloque para evitar redundancias.</li>
                <li>Lenguaje Accesible: Se han simplificado algunas oraciones para hacer el texto más comprensible sin perder la formalidad requerida.</li>
            </ul>
          </div>
          <hr>
          <div id="section-5">
            <h3>6. Publicidad, promociones y eventos</h3>
            <p>Esta sección detalla las condiciones y procedimientos aplicables a las promociones, concursos y actividades publicitarias gestionadas por Descuentos Médicos App, unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>.</p>
        
            <h4>6.1 Condiciones para Promociones y Concursos</h4>
            <p>Requisitos de Participación:</p>
            <ul>
                <li>Las promociones y concursos organizados por Descuentos Médicos App, ya sea de forma directa o en alianza con terceros, estarán sujetos a términos y condiciones específicos que se comunicarán previamente a los participantes.</li>
                <li>La participación implica la aceptación total de las reglas establecidas, que incluirán requisitos de elegibilidad (por ejemplo, restricciones de edad o región), fechas de vigencia, restricciones y mecanismos de selección de ganadores.</li>
            </ul>
            <p>Acumulación de Ofertas:</p>
            <ul>
                <li>Los descuentos y promociones ofrecidos no serán acumulables con otras ofertas, a menos que se indique expresamente en los términos de la promoción.</li>
            </ul>
            <p>Responsabilidad de Terceros:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> no garantizan los descuentos o beneficios ofrecidos por terceros, ya que estos son responsabilidad exclusiva de los aliados comerciales o prestadores de servicios participantes.</li>
            </ul>
            <p>Condiciones para Premios:</p>
            <ul>
                <li>En concursos que impliquen la entrega de premios, los ganadores deberán cumplir con los requisitos establecidos y aceptar las condiciones para la entrega.</li>
                <li>Los premios no serán transferibles ni canjeables por dinero en efectivo, salvo que se indique expresamente lo contrario en los términos del concurso.</li>
            </ul>
            <p>Notificación y Modificación:</p>
            <ul>
                <li>La plataforma se reserva el derecho de modificar, suspender o cancelar cualquier promoción o concurso en cualquier momento, sin previo aviso, en caso de circunstancias imprevistas o fuerza mayor.</li>
                <li>Cualquier cambio en las condiciones de una promoción será comunicado a los usuarios mediante los canales oficiales (correo electrónico, notificaciones en la plataforma, sitio web o redes sociales) con un plazo razonable de antelación (por ejemplo, 15 días hábiles).</li>
                <li>Una vez modificados, los términos actualizados entrarán en vigencia desde la fecha de publicación y aplicarán a todos los participantes.</li>
            </ul>
        
            <h4>6.2 Uso de Información con Fines Publicitarios</h4>
            <p>Autorización para Comunicaciones Publicitarias:</p>
            <ul>
                <li>Al registrarse y utilizar Descuentos Médicos App, el usuario autoriza expresamente el uso de su información de contacto para recibir comunicaciones relacionadas con promociones, novedades, eventos y otros contenidos publicitarios.</li>
            </ul>
            <p>Protección de Datos y Uso Responsable:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> se comprometen a respetar la privacidad y protección de datos personales. La información será utilizada únicamente de acuerdo con las políticas de privacidad vigentes y conforme a la legislación colombiana.</li>
            </ul>
            <p>Opción de Exclusión:</p>
            <ul>
                <li>Los usuarios podrán solicitar, en cualquier momento, la exclusión de sus datos para fines publicitarios mediante una solicitud a través de los canales de atención habilitados en la plataforma.</li>
            </ul>
            <p>Prohibición de Comercialización de Datos:</p>
            <ul>
                <li>La plataforma no comercializa, vende ni comparte la información personal de los usuarios con terceros para fines publicitarios sin el consentimiento expreso del usuario.</li>
            </ul>
            <p>Medidas de Seguridad:</p>
            <ul>
                <li>Cualquier dato utilizado en campañas promocionales se tratará con estrictas medidas de seguridad, garantizando la confidencialidad y transparencia.</li>
            </ul>
        
            <h4>6.3 Modificaciones o Cancelación de Promociones</h4>
            <p>Derecho de Modificación:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> se reservan el derecho de modificar, suspender o cancelar cualquier promoción o concurso en cualquier momento y sin previo aviso, en caso de circunstancias imprevistas, fuerza mayor o situaciones que afecten la operatividad de la oferta.</li>
            </ul>
            <p>Notificación de Cambios:</p>
            <ul>
                <li>Los cambios en las condiciones de una promoción se comunicarán a los usuarios a través de los canales oficiales (sitio web, redes sociales o notificaciones directas), con una antelación razonable para que los usuarios puedan tomar decisiones informadas.</li>
            </ul>
            <p>Cancelación de Promociones:</p>
            <ul>
                <li>En caso de cancelación de una promoción antes de su fecha de finalización, Descuentos Médicos App no estará obligada a otorgar compensaciones o beneficios adicionales, salvo que se especifique en los términos particulares de la promoción.</li>
            </ul>
            <p>Responsabilidad de Terceros Aliados:</p>
            <ul>
                <li>La plataforma no se hace responsable de la disponibilidad de descuentos o beneficios ofrecidos por terceros aliados, quienes pueden modificar sus propias condiciones sin previo aviso.</li>
            </ul>
        
            <h4>6.4 Responsabilidad en la Difusión Comercial</h4>
            <p>Canal de Difusión:</p>
            <ul>
                <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> actúan únicamente como un canal de difusión comercial, facilitando información sobre promociones, descuentos y servicios de salud ofrecidos por terceros aliados.</li>
            </ul>
            <p>Propósito Informativo:</p>
            <ul>
                <li>La publicidad y el material promocional difundidos a través de la plataforma tienen un carácter meramente informativo y no constituyen una garantía sobre la disponibilidad, calidad, precios o condiciones finales de los servicios ofrecidos por los prestadores.</li>
            </ul>
            <p>Exoneración de Responsabilidad:</p>
            <ul>
                <li>La plataforma no se hace responsable por errores, cambios o modificaciones en la información publicitaria proporcionada por terceros, siendo los prestadores los únicos responsables de la veracidad y cumplimiento de las ofertas anunciadas.</li>
            </ul>
            <p>Verificación Directa:</p>
            <ul>
                <li>Se recomienda a los usuarios verificar directamente con el prestador del servicio las condiciones específicas de cualquier promoción antes de realizar una compra o agendar una cita.</li>
            </ul>
            <p>Retiro o Modificación del Contenido:</p>
            <ul>
                <li>Descuentos Médicos App se reserva el derecho de retirar, modificar o suspender cualquier contenido publicitario que no cumpla con sus estándares de calidad, ética comercial o normativas legales aplicables.</li>
            </ul>
          </div>
          <hr>
          <div id="section-6">
            <h3>7. Contenido generado por los usuarios</h3>
            <p>Esta sección establece las pautas, derechos y responsabilidades relacionados con el contenido que los usuarios pueden generar y compartir en Descuentos Médicos App, garantizando un entorno seguro, respetuoso y conforme a la normativa vigente.</p>
        
            <h4>7.1 Definición del Contenido Generado</h4>
            <p>Los usuarios de Descuentos Médicos App pueden generar y compartir contenido en diversas formas, incluyendo, pero no limitándose a:</p>
            <ul>
                <li>Comentarios:
                    <br>
                    <p>Opiniones y experiencias sobre los servicios de salud utilizados a través de la plataforma.</p>
                </li>
                <li>Reseñas y Calificaciones:
                    <br>
                    <p>Evaluaciones de médicos, veterinarios y otros prestadores, que ayudan a otros usuarios a conocer la calidad de la atención recibida.</p>
                </li>
                <li>Publicaciones en Espacios Interactivos:
                    <br>
                    <p>Contenido publicado en foros, blogs u otras secciones interactivas de la aplicación.</p>
                </li>
                <li>Preguntas, Sugerencias y Testimonios:
                    <br>
                    <p>Interacciones en espacios habilitados para el intercambio de experiencias, dudas y recomendaciones.</p>
                </li>
            </ul>
            <p>Todo contenido generado debe cumplir con los principios de respeto, veracidad y legalidad, evitando la difusión de información falsa, ofensiva o que vulnere los derechos de terceros.</p>
            <p>Descuentos Médicos App se reserva el derecho de revisar, moderar, eliminar o restringir cualquier contenido que infrinja estos términos o que pueda afectar negativamente a otros usuarios, profesionales de la salud o la reputación de la plataforma.</p>
            <p><span>Nota:</span> La publicación de contenido por parte de los usuarios no implica respaldo, validación ni asunción de responsabilidad por parte de Descuentos Médicos App o <span>GRUPO PREVENIR EXPRESS SAS</span>. Cada usuario es el único responsable de sus opiniones y aportes.</p>
        
            <h4>7.2 Normas de Publicación y Conducta</h4>
            <p>Reglas y Recomendaciones para la Creación y Difusión de Contenido</p>
            <ul>
                <li>Respeto y Legalidad:
                    <ul>
                        <li>El contenido publicado debe ser veraz, respetuoso y conforme a la legislación vigente en Colombia.</li>
                        <li>No se permitirá la difusión de insultos, discriminación, difamación, acoso, amenazas o cualquier forma de violencia verbal.</li>
                        <li>Queda prohibida la publicación de información falsa o engañosa que pueda dañar la reputación de personas, empresas o profesionales de la salud.</li>
                    </ul>
                </li>
                <li>Privacidad y Confidencialidad:
                    <ul>
                        <li>Los usuarios deben abstenerse de compartir datos personales de terceros sin el consentimiento expreso de estos.</li>
                        <li>Está prohibida la divulgación de información médica privada, diagnósticos o detalles de tratamientos sin autorización.</li>
                    </ul>
                </li>
                <li>Contenido Apropiado:
                    <ul>
                        <li>No se permite la publicación de material ofensivo, obsceno, ilegal o publicitario que promueva actividades ilícitas.</li>
                        <li>Se prohíbe el uso de la plataforma para difundir spam, contenido promocional no autorizado o información comercial engañosa.</li>
                    </ul>
                </li>
                <li>Responsabilidad del Usuario:
                    <ul>
                        <li>Cada usuario es responsable del contenido que publica y debe asegurarse de que cumple con estas normas.</li>
                        <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> no se responsabilizan por el contenido generado por terceros, aunque se reservan el derecho de moderar, editar o eliminar cualquier publicación que infrinja estas reglas.</li>
                    </ul>
                </li>
                <li>Sanciones por Incumplimiento:
                    <ul>
                        <li>El incumplimiento de estas normas podrá resultar en la eliminación del contenido, suspensión o bloqueo de la cuenta del usuario.</li>
                        <li>En caso de causar daño a terceros, se podrán emprender acciones legales adicionales conforme a la legislación vigente.</li>
                    </ul>
                </li>
            </ul>
        
            <h4>7.3 Derechos y Responsabilidades sobre el Contenido</h4>
            <p>Propiedad Intelectual y Responsabilidad por el Material Publicado</p>
            <ul>
                <li>Propiedad del Contenido:
                    <ul>
                        <li>Al publicar contenido en la plataforma, el usuario otorga a <span>GRUPO PREVENIR EXPRESS SAS</span> una licencia no exclusiva, gratuita y transferible para reproducir, distribuir, modificar y utilizar dicho contenido con fines operativos, promocionales o de mejora del servicio.</li>
                        <li>El usuario conserva la propiedad intelectual de sus publicaciones, aunque se establece que dicho contenido podrá permanecer en la plataforma incluso después de la eliminación de la cuenta, en la medida en que haya sido compartido o comentado por otros.</li>
                    </ul>
                </li>
                <li>Responsabilidad del Usuario:
                    <ul>
                        <li>Cada usuario es el único responsable del contenido que publica y debe garantizar que no infringe derechos de autor, marcas registradas, patentes u otros derechos de propiedad intelectual de terceros.</li>
                    </ul>
                </li>
                <li>Uso Apropiado y Eliminación del Contenido:
                    <ul>
                        <li>La plataforma se reserva el derecho de eliminar, modificar o restringir cualquier contenido que infrinja derechos de terceros, contenga información falsa o engañosa, sea ofensivo, difamatorio o que contravenga las normas de conducta establecidas, o que viole la legislación de protección de datos.</li>
                    </ul>
                </li>
                <li>Reportes y Sanciones:
                    <ul>
                        <li>Los usuarios pueden reportar contenido inapropiado o que infrinja derechos a través de los canales oficiales.</li>
                        <li>El incumplimiento de estas disposiciones podrá dar lugar a sanciones, que incluyen desde advertencias hasta la suspensión o eliminación definitiva de la cuenta del usuario, además de posibles acciones legales.</li>
                    </ul>
                </li>
            </ul>
        
            <h4>7.4 Procedimientos de Moderación y Eliminación</h4>
            <p>Revisión y Gestión del Contenido Inapropiado</p>
            <ul>
                <li>Revisión y Moderación:
                    <ul>
                        <li>Descuentos Médicos App y <span>GRUPO PREVENIR EXPRESS SAS</span> se reservan el derecho de supervisar y moderar el contenido generado por los usuarios para asegurar que cumpla con las normas establecidas.</li>
                        <li>La revisión puede realizarse de forma automática (utilizando sistemas de detección) o manualmente por el equipo de moderación, dependiendo de la naturaleza e impacto del contenido.</li>
                    </ul>
                </li>
                <li>Criterios para la Eliminación:
                    <p>Un contenido podrá ser eliminado, editado o restringido si:</p>
                    <ul>
                        <li>Contiene lenguaje ofensivo, difamatorio, discriminatorio o que incite al odio.</li>
                        <li>Difunde información falsa, engañosa o sin fundamentos verificables.</li>
                        <li>Infringe derechos de autor, propiedad intelectual o la privacidad de terceros.</li>
                        <li>Publicita servicios no autorizados o incluye spam, enlaces maliciosos o virus.</li>
                        <li>Atenta contra la reputación de la plataforma, sus aliados comerciales o profesionales de la salud.</li>
                        <li>Contiene información confidencial, privada o médica sin la autorización correspondiente.</li>
                    </ul>
                </li>
                <li>Procedimiento de Eliminación y Reportes:
                    <ul>
                        <li>Los usuarios pueden reportar contenido inapropiado a través de los canales oficiales de la plataforma.</li>
                        <li>Una vez recibido un reporte, el equipo de moderación analizará el caso y tomará las medidas necesarias en un plazo razonable.</li>
                        <li>Si se determina que el contenido infringe las normas, se procederá a su eliminación inmediata y se podrán aplicar sanciones al usuario responsable, como suspensión o bloqueo de la cuenta.</li>
                    </ul>
                </li>
                <li>Derecho de Apelación:
                    <ul>
                        <li>Si un usuario considera que su contenido ha sido eliminado de manera injustificada, podrá solicitar una revisión a través de los canales de atención oficiales.</li>
                        <li>Descuentos Médicos App evaluará el caso y, si se comprueba que el contenido no infringió las normas, podrá restablecerlo.</li>
                    </ul>
                </li>
                <li>Sanciones por Incumplimiento:
                    <ul>
                        <li>Dependiendo de la gravedad de la infracción, las medidas podrán incluir advertencias, restricciones temporales o la suspensión/eliminación definitiva de la cuenta del usuario.</li>
                        <li>En caso de daño a terceros o a la reputación de la plataforma, se podrán emprender acciones legales adicionales conforme a la legislación vigente.</li>
                    </ul>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-7">
            <h3>8. Código de ética y responsabilidad del usuario</h3>
            <p>Esta sección establece las expectativas de conducta y los mecanismos de responsabilidad para los usuarios de Descuentos Médicos App, garantizando un entorno seguro, respetuoso y ético en el uso de la plataforma.</p>
        
            <h4>8.1 Normas de Comportamiento</h4>
            <p>Expectativas de Conducta y Uso Ético:</p>
            <ul>
                <li>Respeto y Trato Adecuado:
                    <ul>
                        <li>Los usuarios deben interactuar con respeto hacia todos los miembros de la plataforma, incluidos pacientes, profesionales de la salud, empresas aliadas y el equipo de Descuentos Médicos App.</li>
                        <li>Se prohíbe el uso de lenguaje ofensivo, discriminatorio, difamatorio o amenazante en cualquier interacción.</li>
                    </ul>
                </li>
                <li>Uso Responsable de la Información:
                    <ul>
                        <li>Toda la información compartida debe ser veraz, precisa y basada en hechos reales.</li>
                        <li>No se permitirá la difusión de datos falsos, engañosos o manipulados con la intención de desinformar o perjudicar a terceros.</li>
                        <li>Los usuarios deben abstenerse de divulgar datos personales o información confidencial de terceros sin su consentimiento expreso.</li>
                    </ul>
                </li>
                <li>Uso Adecuado de la Plataforma:
                    <ul>
                        <li>Descuentos Médicos App debe utilizarse exclusivamente para facilitar el acceso a descuentos en servicios de salud y conectar a los usuarios con profesionales y entidades médicas.</li>
                        <li>Queda estrictamente prohibido el uso de la plataforma para actividades ilícitas, fraudes, promoción de servicios no autorizados, spam o cualquier otro uso indebido.</li>
                    </ul>
                </li>
                <li>Relación con Profesionales y Empresas Aliadas:
                    <ul>
                        <li>Se espera que los usuarios interactúen de forma ética y respetuosa con los profesionales de la salud y las empresas aliadas, valorando su tiempo, experiencia y condiciones de servicio.</li>
                        <li>Cualquier desacuerdo o queja debe gestionarse a través de los canales adecuados, evitando ataques personales o difamación.</li>
                    </ul>
                </li>
                <li>Cumplimiento de Normas y Legislación Vigente:
                    <ul>
                        <li>Los usuarios deben acatar las leyes colombianas aplicables al uso de plataformas digitales, protección de datos, propiedad intelectual y ética en servicios de salud.</li>
                        <li>El incumplimiento de estas normas podrá dar lugar a sanciones, que incluyen desde advertencias y restricciones hasta la suspensión o eliminación de la cuenta, sin perjuicio de acciones legales adicionales.</li>
                    </ul>
                </li>
            </ul>
        
            <h4>8.2 Responsabilidad por Uso Inadecuado</h4>
            <p>Los usuarios que hagan uso inadecuado de la plataforma estarán sujetos a medidas disciplinarias proporcionales a la gravedad de la infracción. Esto puede incluir:</p>
            <ul>
                <li>Advertencias Formales:
                    <br>
                    <p>Notificaciones iniciales para corregir conductas inapropiadas.</p>
                </li>
                <li>Restricciones Temporales:
                    <br>
                    <p>Limitación del acceso a ciertas funcionalidades de la plataforma durante un período determinado.</p>
                </li>
                <li>Suspensión o Eliminación de la Cuenta:
                    <br>
                    <p>En casos de infracciones graves o reiteradas, se podrá proceder a la suspensión temporal o eliminación definitiva de la cuenta del usuario.</p>
                </li>
                <li>Acciones Legales:
                    <br>
                    <p>Si el uso indebido causa perjuicios a terceros o afecta la integridad y reputación de la plataforma, se podrán emprender acciones legales conforme a la legislación colombiana.</p>
                </li>
            </ul>
            <p><span>Nota:</span> Se definirán de forma interna los criterios específicos que constituyen “uso inadecuado” para asegurar la transparencia del proceso.</p>
        
            <h4>8.3 Procedimientos para Reportar Incidentes</h4>
            <p>Canales y Métodos para Reportar Conductas o Contenidos Inapropiados:</p>
            <ul>
                <li>Tipos de Incidentes Reportables:
                    <ul>
                        <li>Uso indebido de la plataforma, incluyendo fraude o actividades ilícitas.</li>
                        <li>Publicación de contenido ofensivo, difamatorio, falso o engañoso.</li>
                        <li>Suplantación de identidad o manipulación de información.</li>
                        <li>Acciones que comprometan la seguridad o privacidad de otros usuarios.</li>
                        <li>Incumplimiento de las normas establecidas en este código de ética.</li>
                    </ul>
                </li>
                <li>Canales de Reporte Disponibles:
                    <ul>
                        <li>Formulario en la Plataforma:
                            <br>
                            <p>Utiliza la opción "Reportar contenido" o "Soporte" disponible en la aplicación o sitio web.</p>
                        </li>
                        <li>Correo Electrónico:
                            <br>
                            <p>Envía un reporte detallado al correo oficial de atención al usuario: [correo de soporte].</p>
                        </li>
                        <li>Línea de Atención Telefónica:
                            <br>
                            <p>Disponible en horario hábil para recibir denuncias y consultas.</p>
                        </li>
                        <li>Chat de Soporte:
                            <br>
                            <p>Canal en tiempo real para reportar incidentes de manera inmediata.</p>
                        </li>
                    </ul>
                </li>
                <li>Proceso de Evaluación y Respuesta:
                    <ul>
                        <li>Una vez recibido el reporte, el equipo de moderación evaluará el caso en un plazo razonable (por ejemplo, 5-7 días hábiles).</li>
                        <li>Dependiendo de la gravedad, se tomarán las medidas disciplinarias correspondientes.</li>
                        <li>En caso de requerir más información, se contactará al denunciante para solicitar aclaraciones adicionales.</li>
                    </ul>
                </li>
                <li>Confidencialidad y Protección del Denunciante:
                    <ul>
                        <li>Los reportes serán tratados de manera confidencial y segura, protegiendo la identidad del denunciante.</li>
                        <li>La identidad no se revelará a terceros sin consentimiento, salvo requerimiento legal.</li>
                    </ul>
                </li>
                <li>Compromiso con una Comunidad Segura:
                    <ul>
                        <li>La plataforma promoverá un entorno de respeto y transparencia, evaluando los reportes con imparcialidad.</li>
                        <li>El uso malintencionado del sistema de denuncias (como reportes falsos) podrá dar lugar a sanciones.</li>
                    </ul>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-8">
            <h3>9. Aplicación móvil, web y soporte técnico</h3>
            <p>Esta sección tiene como objetivo informar a los usuarios sobre los requisitos técnicos necesarios para el correcto funcionamiento de la plataforma, las políticas de actualizaciones y mantenimiento, así como los canales y limitaciones del soporte técnico.</p>
        
            <h4>9.1 Requisitos Técnicos y Compatibilidad</h4>
            <p>Importancia de los Requisitos:</p>
            <p>Para garantizar una experiencia óptima y segura, es esencial que los usuarios utilicen dispositivos y software compatibles con Descuentos Médicos App. El incumplimiento de estos requisitos podría afectar el rendimiento y la seguridad de la plataforma.</p>
            <p>Dispositivos Compatibles:</p>
            <ul>
                <li>Aplicación Móvil:
                    <br>
                    <p>Disponible para teléfonos inteligentes y tabletas que ejecuten sistemas operativos Android e iOS.</p>
                </li>
                <li>Versión Web:
                    <br>
                    <p>Accesible desde navegadores modernos en computadoras y dispositivos móviles.</p>
                </li>
            </ul>
            <p>Sistemas Operativos y Requisitos Mínimos:</p>
            <ul>
                <li>Android:
                    <br>
                    <p>Versión mínima recomendada: Android 8.0 (Oreo) o superior.</p>
                </li>
                <li>iOS:
                    <br>
                    <p>Versión mínima recomendada: iOS 12 o superior.</p>
                </li>
                <li>Navegadores Compatibles:
                    <br>
                    <p>Se recomienda utilizar las últimas versiones de Google Chrome, Mozilla Firefox, Safari o Microsoft Edge.</p>
                </li>
            </ul>
            <p>Conectividad y Rendimiento:</p>
            <ul>
                <li>Se recomienda contar con una conexión a Internet estable (Wi-Fi o datos móviles 4G/5G) para asegurar un funcionamiento óptimo.</li>
                <li>Algunas funciones pueden requerir permisos específicos del dispositivo, como acceso a la cámara, ubicación y notificaciones.</li>
            </ul>
            <p>Actualizaciones y Mantenimiento:</p>
            <ul>
                <li>Es recomendable mantener la aplicación y el navegador actualizados para evitar problemas de compatibilidad y mejorar la seguridad.</li>
                <li>Descuentos Médicos App no garantiza soporte en dispositivos o software que no cumplan con los requisitos mínimos.</li>
            </ul>
        
            <h4>9.2 Actualizaciones y Mantenimiento</h4>
            <p>Frecuencia de Actualizaciones:</p>
            <ul>
                <li>La plataforma realiza actualizaciones periódicas para mejorar la estabilidad, seguridad y experiencia del usuario.</li>
                <li>Las actualizaciones pueden incluir nuevas funcionalidades, mejoras en la interfaz, corrección de errores y optimización del rendimiento.</li>
            </ul>
            <p>Método de Actualización:</p>
            <ul>
                <li>En dispositivos móviles:
                    <br>
                    <p>Las actualizaciones se distribuyen a través de Google Play Store y App Store.</p>
                </li>
                <li>En la versión web:
                    <br>
                    <p>Los cambios se aplican automáticamente sin necesidad de intervención del usuario.</p>
                </li>
            </ul>
            <p>Notificación de Actualizaciones:</p>
            <ul>
                <li>Se notificará a los usuarios sobre actualizaciones críticas o cambios importantes mediante correo electrónico y notificaciones en la plataforma.</li>
                <li>En ciertos casos, será necesario aceptar manualmente la actualización para continuar utilizando el servicio.</li>
            </ul>
            <p>Mantenimiento del Sistema:</p>
            <ul>
                <li>Se programan mantenimientos técnicos periódicos para optimizar la infraestructura.</li>
                <li>Durante estos períodos, algunos servicios pueden estar temporalmente limitados o no disponibles.</li>
            </ul>
            <p>Responsabilidad del Usuario:</p>
            <ul>
                <li>Es responsabilidad del usuario mantener actualizado su dispositivo y software.</li>
                <li>Descuentos Médicos App no se hace responsable de problemas derivados del uso de versiones desactualizadas.</li>
            </ul>
            <p>Soporte y Reporte de Errores:</p>
            <ul>
                <li>Si el usuario experimenta problemas tras una actualización, puede reportarlos a través de los canales de soporte técnico para que se tomen las medidas pertinentes.</li>
            </ul>
        
            <h4>9.3 Canales de Soporte Técnico</h4>
            <p>Métodos de Contacto:</p>
            <p>Para resolver inquietudes y problemas técnicos, se han habilitado los siguientes canales de soporte:</p>
            <ul>
                <li>Chat en la Plataforma:
                    <br>
                    <p>Disponible en la aplicación móvil y en el sitio web para consultas en tiempo real.</p>
                </li>
                <li>Correo Electrónico:
                    <br>
                    <p>Los usuarios pueden enviar solicitudes y reportes detallados al correo oficial de soporte: [correo de soporte].</p>
                </li>
                <li>Línea de Atención Telefónica:
                    <br>
                    <p>Disponible durante el horario hábil para resolver dudas y problemas técnicos.</p>
                </li>
                <li>Centro de Ayuda y Preguntas Frecuentes (FAQ):
                    <br>
                    <p>Sección que ofrece soluciones a problemas comunes y guías de uso de la plataforma.</p>
                </li>
            </ul>
            <p>Horarios de Atención:</p>
            <ul>
                <li>El soporte técnico está disponible de lunes a viernes, de 8:00 a.m. a 6:00 p.m., y los sábados de 9:00 a.m. a 1:00 p.m.</li>
                <li>Para incidencias urgentes fuera de estos horarios, se recomienda utilizar el chat o enviar un correo electrónico, donde se gestionará la solicitud a la brevedad posible.</li>
            </ul>
            <p>Tiempo de Respuesta y Resolución:</p>
            <ul>
                <li>Las consultas se atenderán en orden de recepción, priorizando los casos críticos.</li>
                <li>En la mayoría de los casos, se proporcionará una respuesta inicial en un plazo máximo de 24 horas hábiles.</li>
            </ul>
            <p>Compromiso con la Calidad del Servicio:</p>
            <ul>
                <li>Se trabaja continuamente en mejorar la atención y eficiencia del soporte técnico.</li>
                <li>Los usuarios pueden enviar comentarios o sugerencias sobre su experiencia para optimizar el servicio.</li>
            </ul>
            <p>Limitaciones del Soporte:</p>
            <ul>
                <li>La asistencia se centra exclusivamente en problemas relacionados con el funcionamiento de la plataforma.</li>
                <li>No se brinda soporte para aspectos médicos, facturación de prestadores de servicios o problemas relacionados con dispositivos externos.</li>
            </ul>
        
            <h4>9.4 Limitaciones del Servicio de Soporte</h4>
            <p>Alcance del Soporte Técnico:</p>
            <ul>
                <li>La asistencia proporcionada se enfoca en el funcionamiento de la aplicación móvil y la plataforma web.</li>
                <li>Se brinda ayuda para resolver problemas de acceso, errores en la interfaz, configuración de cuentas y actualizaciones del sistema.</li>
            </ul>
            <p>Exclusiones del Servicio:</p>
            <ul>
                <li>No se ofrece soporte en cuestiones de facturación, cobros o reembolsos, ya que estos procesos son responsabilidad de los prestadores de servicios de salud o profesionales independientes.</li>
                <li>No se gestiona asistencia médica o veterinaria, ni se atienden consultas sobre diagnósticos, tratamientos o resultados de los servicios contratados a través de la plataforma.</li>
                <li>Problemas derivados del mal funcionamiento de dispositivos, redes de Internet o configuraciones externas no están cubiertos por el soporte técnico.</li>
            </ul>
            <p>Responsabilidad del Usuario:</p>
            <ul>
                <li>Es responsabilidad del usuario mantener su dispositivo actualizado y asegurarse de que cumple con los requisitos técnicos.</li>
                <li>Se recomienda revisar la sección de Preguntas Frecuentes (FAQ) y otras guías de ayuda antes de contactar al soporte técnico.</li>
            </ul>
            <p>Modificaciones y Alcance del Servicio:</p>
            <ul>
                <li>La empresa se reserva el derecho de modificar o actualizar las condiciones del soporte técnico según las necesidades del servicio.</li>
                <li>Cualquier cambio se comunicará oportunamente a través de los canales oficiales.</li>
            </ul>
          </div>
          <hr>
          <div id="section-9">
            <h3>10. Transferencia de datos y cambios corporativos</h3>
            <p>Esta sección describe cómo Descuentos Médicos App, unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>, gestiona la transferencia de datos en situaciones de cambios corporativos y establece los mecanismos de notificación y consentimiento para dichas transferencias.</p>
        
            <h4>10.1 Procedimientos de Transferencia de Datos</h4>
            <p>Casos en los que Aplica:</p>
            <ul>
                <li>Cambios Corporativos:
                    <br>
                    <p>En situaciones de fusión, adquisición, escisión o reestructuración de <span>GRUPO PREVENIR EXPRESS SAS</span>, los datos de los usuarios podrán ser transferidos a la entidad sucesora o vinculada, garantizando el mantenimiento de la protección y confidencialidad de la información.</p>
                </li>
                <li>Cumplimiento Legal:
                    <br>
                    <p>La transferencia se realizará siempre en cumplimiento con las leyes de protección de datos aplicables en Colombia.</p>
                </li>
            </ul>
            <p>Requisitos y Seguridad en la Transferencia:</p>
            <ul>
                <li>Niveles de Protección:
                    <br>
                    <p>Se asegurará que los datos sean tratados con los mismos niveles de seguridad, confidencialidad y con el mismo propósito para el cual fueron recolectados.</p>
                </li>
                <li>Notificación de Cambios:
                    <br>
                    <p>En caso de cambios sustanciales en la administración de la información, se notificará a los usuarios con antelación mediante los canales oficiales de la plataforma (por ejemplo, correo electrónico, notificaciones en la plataforma o publicaciones en el sitio web).</p>
                </li>
            </ul>
            <p>Derechos de los Usuarios:</p>
            <ul>
                <li>Ejercicio de Derechos:
                    <br>
                    <p>Los titulares de la información podrán ejercer sus derechos de conocer, actualizar, rectificar o solicitar la eliminación de sus datos conforme a la legislación vigente.</p>
                </li>
                <li>Opción de Retiro:
                    <br>
                    <p>Si el usuario no está de acuerdo con la transferencia de sus datos, podrá solicitar el retiro de su información de la plataforma antes de que el cambio entre en vigencia.</p>
                </li>
            </ul>
            <p>Modificaciones y Actualizaciones:</p>
            <ul>
                <li>Reserva de Derechos:
                    <br>
                    <p><span>GRUPO PREVENIR EXPRESS SAS</span> se reserva el derecho de modificar estas condiciones, siempre asegurando el cumplimiento normativo.</p>
                </li>
                <li>Comunicación de Cambios:
                    <br>
                    <p>Cualquier modificación será comunicada oportunamente a los usuarios a través de los canales oficiales de la plataforma.</p>
                </li>
            </ul>
        
            <h4>10.2 Notificación y Consentimiento</h4>
            <p>Mecanismos de Notificación:</p>
            <ul>
                <li>En caso de fusión, adquisición, reestructuración u otro cambio que implique la transferencia de datos personales, <span>GRUPO PREVENIR EXPRESS SAS</span> notificará a los usuarios mediante:
                    <ul>
                        <li>Correo Electrónico: Enviado a la dirección registrada.</li>
                        <li>Notificaciones en la Plataforma: A través de mensajes directos en la aplicación o el sitio web.</li>
                        <li>Publicaciones Oficiales: Anuncios en los canales oficiales (sitio web, redes sociales, etc.).</li>
                    </ul>
                </li>
                <li>La notificación incluirá información clara sobre el alcance de los cambios, la entidad que asumirá la administración de los datos y cualquier impacto relevante para los usuarios.</li>
            </ul>
            <p>Obtención del Consentimiento:</p>
            <ul>
                <li>Si la legislación aplicable requiere la autorización del usuario para la transferencia de sus datos, se habilitará un mecanismo para que el usuario otorgue o rechace su consentimiento antes de que los cambios entren en vigor.</li>
                <li>En caso de que el usuario no acepte la nueva política de transferencia, podrá solicitar la eliminación de su cuenta y de sus datos personales de la plataforma.</li>
            </ul>
            <p>Tiempos y Plazos:</p>
            <ul>
                <li>La notificación se realizará con una anticipación razonable (por ejemplo, al menos 15 días hábiles) para permitir a los usuarios evaluar las modificaciones y tomar decisiones informadas.</li>
                <li>Los cambios se aplicarán únicamente después de haber transcurrido el plazo establecido en la comunicación oficial.</li>
            </ul>
            <p>Protección y Continuidad:</p>
            <ul>
                <li>La transferencia de datos se realizará manteniendo los mismos niveles de seguridad, privacidad y confidencialidad que se aplican al tratamiento de la información en la plataforma.</li>
                <li>No se compartirán datos con terceros no autorizados, ni se modificarán los fines para los cuales fueron recolectados, sin obtener el consentimiento correspondiente.</li>
            </ul>
          </div>      
          <hr>
          <div id="section-10">
            <h3>11. Legislación aplicable y resolución de conflictos</h3>
            <p>Esta sección establece el marco legal que rige el uso de Descuentos Médicos App, así como los mecanismos y procedimientos para resolver disputas y presentar reclamos relacionados con la plataforma.</p>
        
            <h4>11.1 Ley Aplicable y Jurisdicción</h4>
            <p>Normativa Aplicable:</p>
            <ul>
                <li>Descuentos Médicos App, como unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>, se rige por la legislación vigente en la República de Colombia.</li>
                <li>Se aplican las disposiciones del Código de Comercio, el Estatuto del Consumidor (Ley 1480 de 2011), la Ley de Protección de Datos Personales (Ley 1581 de 2012) y demás normativas relacionadas con el comercio electrónico y la prestación de servicios digitales.</li>
            </ul>
            <p>Jurisdicción y Resolución de Disputas:</p>
            <ul>
                <li>Cualquier controversia derivada del uso de la plataforma o de los servicios ofrecidos por terceros se resolverá conforme a las leyes colombianas.</li>
                <li>Las partes se comprometen a agotar mecanismos de conciliación y resolución alternativa de conflictos antes de recurrir a instancias judiciales.</li>
                <li>De no alcanzarse un acuerdo, cualquier litigio será competencia de los juzgados y tribunales de Colombia, en la jurisdicción correspondiente a la sede principal de <span>GRUPO PREVENIR EXPRESS SAS</span>.</li>
            </ul>
            <p>Limitación de Responsabilidad:</p>
            <ul>
                <li>Descuentos Médicos App no asume responsabilidad por disputas entre usuarios y profesionales de la salud, ya que cada especialista actúa de manera independiente.</li>
                <li>Las controversias relacionadas con facturación, atención médica o cualquier otro servicio contratado a través de la plataforma deberán resolverse directamente entre las partes involucradas.</li>
            </ul>
        
            <h4>11.2 Mecanismos Alternativos de Resolución</h4>
            <p>Conciliación y Mediación:</p>
            <ul>
                <li>Antes de acudir a instancias judiciales, las partes deberán intentar resolver cualquier disputa relacionada con el uso de la plataforma mediante conciliación o mediación, de acuerdo con la legislación colombiana.</li>
                <li>Se recomienda que la conciliación se lleve a cabo a través de centros de conciliación legalmente autorizados o mediante mecanismos internos ofrecidos por <span>GRUPO PREVENIR EXPRESS SAS</span>.</li>
            </ul>
            <p>Arbitraje:</p>
            <ul>
                <li>Si la conciliación no resulta, las partes podrán optar por someter la disputa a un tribunal de arbitramento, conforme a las normas del Centro de Arbitraje y Conciliación de la Cámara de Comercio correspondiente.</li>
                <li>El tribunal, compuesto por uno o más árbitros designados según la naturaleza del conflicto, emitirá una decisión definitiva y de obligatorio cumplimiento.</li>
            </ul>
            <p>Quejas y Reclamaciones:</p>
            <ul>
                <li>Los usuarios pueden presentar quejas y reclamaciones a través de los canales de atención al cliente disponibles en la plataforma.</li>
                <li>En disputas que involucren servicios de salud, facturación o atención por parte de profesionales independientes, el usuario deberá dirigir su reclamo directamente a la entidad o especialista correspondiente.</li>
            </ul>
            <p>Exclusión de Responsabilidad:</p>
            <ul>
                <li>Descuentos Médicos App, como unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>, no interviene en la resolución de conflictos entre usuarios y profesionales de la salud, ya que cada especialista actúa de manera independiente.</li>
                <li>Los mecanismos aquí descritos aplican exclusivamente a disputas relacionadas con el uso de la plataforma y sus funcionalidades.</li>
            </ul>
        
            <h4>11.3 Procedimientos para Presentar Reclamos</h4>
            <p>Canales de Atención:</p>
            <p>Los usuarios pueden presentar sus reclamos relacionados con el uso de la plataforma a través de los siguientes medios oficiales:</p>
            <ul>
                <li>Correo Electrónico: Enviar el reclamo a la dirección de contacto designada.</li>
                <li>Línea de Atención Telefónica: Contactar al número de soporte proporcionado durante el horario de atención.</li>
                <li>Formulario de Contacto en la Plataforma: Utilizar la sección de ayuda o soporte disponible en la aplicación o el sitio web.</li>
                <li>Atención Presencial: Dirigirse a la sede de <span>GRUPO PREVENIR EXPRESS SAS</span>, cuando corresponda.</li>
            </ul>
            <p>Pasos para la Presentación de un Reclamo:</p>
            <ul>
                <li>Identificación: Proporcionar datos personales y de contacto que permitan identificar al usuario.</li>
                <li>Descripción del Reclamo: Detallar de forma clara y precisa la situación, incluyendo fechas, evidencias y otros datos relevantes.</li>
                <li>Anexos: Adjuntar documentos de respaldo (capturas de pantalla, correos, facturas, etc.) que sustenten el reclamo.</li>
                <li>Número de Radicado: Una vez recibido el reclamo, se asignará un número de seguimiento para garantizar su trazabilidad.</li>
            </ul>
            <p>Tiempos de Respuesta:</p>
            <ul>
                <li>Confirmación Inicial: Se enviará una respuesta preliminar en un plazo máximo de 48 horas hábiles.</li>
                <li>Resolución Definitiva: La respuesta final se proporcionará en un plazo de hasta 15 días hábiles, conforme a la normativa de protección al consumidor en Colombia.</li>
                <li>Notificación de Retrasos: Si se requiere un tiempo adicional, se informará al usuario el motivo y se establecerá una nueva fecha estimada de respuesta.</li>
            </ul>
            <p>Casos que Deben Dirigirse a Terceros:</p>
            <ul>
                <li>Reclamos sobre facturación y pagos deben dirigirse directamente al profesional o entidad prestadora.</li>
                <li>Las reclamaciones relacionadas con la calidad de la atención deben presentarse ante la entidad responsable del servicio.</li>
                <li>En caso de servicios contratados mediante convenios de terceros (marca blanca), la responsabilidad recae en la entidad prestadora.</li>
            </ul>
            <p>Escalamiento del Reclamo:</p>
            <ul>
                <li>Si el usuario no está satisfecho con la respuesta inicial, podrá solicitar una revisión adicional dentro de los 5 días hábiles siguientes.</li>
                <li>En caso de no alcanzar una solución satisfactoria, el usuario podrá acudir a las autoridades competentes en Colombia, como la Superintendencia de Industria y Comercio (SIC) o la Superintendencia Nacional de Salud, según corresponda.</li>
            </ul>
        
            <h4>11.4 Vigencia y Terminación de los Términos</h4>
            <p>Duración de la Vigencia:</p>
            <ul>
                <li>Estos términos y condiciones estarán vigentes de manera indefinida a partir de su publicación en Descuentos Médicos App.</li>
                <li><span>GRUPO PREVENIR EXPRESS SAS</span> se reserva el derecho de actualizar, modificar o reemplazar estos términos en cualquier momento, notificando a los usuarios a través de los canales oficiales de la plataforma.</li>
            </ul>
            <p>Condiciones para la Terminación:</p>
            <ul>
                <li>Por parte del usuario:
                    <ul>
                        <li>El usuario podrá dar por terminada su relación con la plataforma en cualquier momento, solicitando la eliminación de su cuenta y cesando el uso de la misma.</li>
                        <li>En caso de tener suscripciones activas, el usuario deberá cumplir con los términos de cancelación aplicables antes de finalizar la relación.</li>
                    </ul>
                </li>
                <li>Por parte de la plataforma:
                    <ul>
                        <li>Descuentos Médicos App podrá suspender o terminar la cuenta de un usuario en casos de incumplimiento de estos términos, uso fraudulento, violación de normativas legales o cambios estratégicos en el modelo de negocio.</li>
                    </ul>
                </li>
            </ul>
            <p>Efectos de la Terminación:</p>
            <ul>
                <li>Una vez terminada la relación, el usuario perderá el acceso a su cuenta y a los beneficios asociados.</li>
                <li>No se generará ningún derecho a reembolsos o compensaciones, salvo lo estipulado en las políticas específicas.</li>
                <li>La información personal será tratada conforme a la Política de Protección de Datos, asegurando su eliminación o anonimización según corresponda.</li>
            </ul>
        </div>
        <hr>
        </div>
      </section>
      <section class="data_protection">
        <h2>Política de protección de datos</h2>
        <p>Descuentos Médicos App, unidad de negocio de <span>GRUPO PREVENIR EXPRESS SAS</span>, se compromete a proteger la privacidad y la integridad de la información personal de sus usuarios. Esta política explica de manera clara y detallada cómo se recopilan, utilizan, almacenan y protegen los datos personales, en conformidad con la Ley 1581 de 2012 y demás normativas aplicables en Colombia.</p>
        <div class="container_content">
          <div id="section-11">
            <h3>1. Finalidad del Tratamiento</h3>
            <p>Objetivos de la Recopilación y Uso de Datos:</p>
            <ul>
                <li>Gestión de Citas:
                    <br>
                    <p>Permite gestionar y coordinar de manera eficiente el agendamiento de citas médicas y veterinarias particulares, garantizando que los usuarios puedan reservar y confirmar sus citas en tiempo real.</p>
                </li>
                <li>Comunicaciones:
                    <br>
                    <p>Facilita el establecimiento de canales de comunicación efectivos para el envío de notificaciones, recordatorios, actualizaciones y promociones, adaptados a las preferencias del usuario.</p>
                </li>
                <li>Análisis y Mejora Continua:
                    <br>
                    <p>La información recopilada se utiliza para analizar el comportamiento en la plataforma, identificar áreas de mejora, optimizar la experiencia del usuario y desarrollar nuevas funcionalidades.</p>
                </li>
                <li>Personalización del Servicio:
                    <br>
                    <p>Los datos permiten adaptar y personalizar la oferta de servicios, mostrando información relevante y promociones adecuadas al perfil y necesidades de cada usuario.</p>
                </li>
                <li>Cumplimiento Normativo y Seguridad:
                    <br>
                    <p>El tratamiento de datos garantiza el cumplimiento de las obligaciones legales y normativas vigentes, implementando medidas de seguridad y confidencialidad que protegen la información personal.</p>
                </li>
            </ul>
            <p>Aplicaciones del Tratamiento:</p>
            <ul>
                <li>Gestión de citas (reserva, confirmación, reprogramación y cancelación).</li>
                <li>Envío de comunicaciones y promociones personalizadas.</li>
                <li>Obtención de estadísticas y elaboración de reportes para mejorar el servicio.</li>
                <li>Personalización de la experiencia del usuario.</li>
            </ul>
          </div>
          <hr>
          <div id="section-12">
            <h3>2. Categorías de Datos Recopilados</h3>
            <ul>
                <li>Datos de Contacto e Identificación:
                    <br>
                    <p>Se recaban datos básicos como nombre completo, número de documento de identidad, dirección de correo electrónico y número de teléfono para identificar y comunicarse con el usuario.</p>
                </li>
                <li>Datos Relacionados con la Salud y Preferencias de Comunicación:
                    <br>
                    <p>Incluyen información necesaria para gestionar citas y personalizar el servicio, como condiciones médicas, historial de citas y preferencias en la recepción de comunicaciones (notificaciones, recordatorios y promociones).</p>
                </li>
                <li>Datos Sensibles (en caso de aplicar):
                    <br>
                    <p>Con el consentimiento expreso del usuario, se podrán recopilar datos sensibles relacionados con la salud, estrictamente necesarios para la prestación de los servicios, garantizando el cumplimiento normativo.</p>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-13">
            <h3>3. Principios y Bases Legales</h3>
            <p>Principios:</p>
            <ul>
                <li>Legalidad:
                    <br>
                    <p>El manejo de la información se realiza conforme a la ley y normativas vigentes.</p>
                </li>
                <li>Finalidad:
                    <br>
                    <p>Los datos se recogen únicamente para fines específicos y legítimos, como la gestión de citas, personalización del servicio y comunicaciones.</p>
                </li>
                <li>Proporcionalidad:
                    <br>
                    <p>Se recaba la información estrictamente necesaria, evitando la recopilación excesiva de datos.</p>
                </li>
                <li>Seguridad:
                    <br>
                    <p>Se implementan medidas técnicas y organizativas para proteger la información contra accesos no autorizados, alteraciones o pérdidas.</p>
                </li>
                <li>Confidencialidad:
                    <br>
                    <p>La información personal no se divulga a terceros sin el consentimiento expreso del usuario, salvo lo exigido por la ley.</p>
                </li>
            </ul>
            <p>Fundamento Legal:</p>
            <ul>
                <li>El tratamiento de datos se fundamenta en la Ley 1581 de 2012 y demás normativas aplicables en Colombia, que garantizan un manejo transparente, seguro y responsable de la información.</li>
            </ul>
          </div>
          <hr>
          <div id="section-14">
            <h3>4. Derechos de los Usuarios</h3>
            <ul>
                <li>Derecho de Acceso:
                    <br>
                    <p>Los usuarios pueden solicitar un reporte detallado de los datos personales que se están tratando.</p>
                </li>
                <li>Derecho de Rectificación:
                    <br>
                    <p>Pueden solicitar la corrección de datos inexactos o incompletos.</p>
                </li>
                <li>Derecho de Cancelación (Eliminación):
                    <br>
                    <p>Tienen el derecho de solicitar la eliminación de sus datos cuando estos ya no sean necesarios para los fines para los que fueron recolectados o si deciden desistir del uso de la plataforma.</p>
                </li>
                <li>Derecho de Oposición:
                    <br>
                    <p>Los usuarios pueden oponerse al tratamiento de sus datos para fines específicos, especialmente si se realiza sin su consentimiento o afecta sus intereses legítimos.</p>
                </li>
                <li>Procedimientos para Ejercer Estos Derechos:
                    <ul>
                        <li>El usuario deberá enviar una solicitud formal a través del formulario de contacto de la plataforma o por correo electrónico al canal designado.</li>
                        <li>La solicitud debe incluir la identificación del usuario y una descripción clara del derecho que desea ejercer.</li>
                        <li>La plataforma responderá en un plazo máximo de 15 días hábiles y, de ser procedente, realizará las acciones necesarias (rectificación, eliminación o restricción).</li>
                    </ul>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-15">
            <h3>5. Medidas de Seguridad</h3>
            <p>Para garantizar la integridad, confidencialidad y disponibilidad de los datos, se implementan las siguientes medidas:</p>
            <p>Tecnologías y Protocolos:</p>
            <ul>
                <li>Cifrado de Datos:
                    <br>
                    <p>Se utiliza cifrado de extremo a extremo (AES-256, TLS 1.2 o superior) para proteger la información en tránsito y en reposo.</p>
                </li>
                <li>Control de Accesos:
                    <br>
                    <p>Se emplea autenticación multifactor (MFA) y gestión de permisos basada en roles (RBAC) para asegurar que solo el personal autorizado tenga acceso a la información sensible.</p>
                </li>
                <li>Monitoreo y Detección de Amenazas:
                    <br>
                    <p>Se utilizan sistemas IDS/IPS y análisis de comportamiento para identificar y mitigar amenazas en tiempo real.</p>
                </li>
            </ul>
            <p>Políticas de Respaldo y Auditoría:</p>
            <ul>
                <li>Respaldo de Datos:
                    <br>
                    <p>Se realizan copias de seguridad periódicas en servidores seguros para prevenir la pérdida de información y facilitar su recuperación en caso de incidentes.</p>
                </li>
                <li>Auditorías de Seguridad:
                    <br>
                    <p>Se llevan a cabo auditorías internas y externas de manera regular para identificar y corregir vulnerabilidades.</p>
                </li>
                <li>Registro y Trazabilidad:
                    <br>
                    <p>Se mantiene un historial detallado de accesos y modificaciones, permitiendo la detección oportuna de actividades inusuales.</p>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-16">
            <h3>6. Transferencia de Datos</h3>
            <p>Condiciones para Compartir Datos con Terceros:</p>
            <ul>
                <li>Los datos personales se comparten únicamente cuando es necesario para la prestación de servicios o para cumplir obligaciones legales y contractuales.</li>
                <li>Se podrán compartir datos con proveedores, aliados comerciales o terceros que actúen en nombre de la plataforma, siempre bajo estrictas medidas de seguridad y confidencialidad.</li>
                <li>La transferencia se realizará con fines específicos, de forma transparente y, en su mayoría, con el consentimiento previo del usuario, excepto cuando la ley exija su divulgación.</li>
            </ul>
            <p>Transferencias Internacionales y Cambios Corporativos:</p>
            <ul>
                <li>En caso de transferencias de datos a terceros fuera de Colombia, se aplicarán salvaguardas que garanticen el mismo nivel de protección, en conformidad con estándares internacionales y la normativa colombiana.</li>
                <li>En situaciones de fusión, adquisición, escisión o reestructuración de <span>GRUPO PREVENIR EXPRESS SAS</span>, los datos podrán ser transferidos a la entidad sucesora, siempre que esta cumpla con las mismas obligaciones de protección de datos.</li>
                <li>Los usuarios serán notificados con antelación de cualquier cambio sustancial en la administración de la información.</li>
            </ul>
          </div>
          <hr>
          <div id="section-17">
            <h3>7. Periodo de Retención y Eliminación</h3>
            <p>Duración del Almacenamiento de Datos:</p>
            <ul>
                <li>Los datos personales se conservarán durante el tiempo necesario para cumplir con las finalidades para las cuales fueron recolectados (gestión de citas, comunicaciones, análisis, etc.).</li>
                <li>Se conservarán también durante el tiempo requerido para cumplir con obligaciones legales, contractuales o regulatorias en Colombia.</li>
            </ul>
            <p>Procedimientos de Eliminación o Anonimización:</p>
            <ul>
                <li>Una vez finalizado el período de retención o cuando los datos ya no sean necesarios, se procederá a su eliminación segura mediante protocolos técnicos y organizativos que impidan la recuperación de la información.</li>
                <li>Alternativamente, se podrá optar por la anonimización de los datos, desvinculándolos irreversiblemente de la identidad del usuario para garantizar la privacidad.</li>
            </ul>
          </div>
          <hr>
          <div id="section-18">
            <h3>8. Modificaciones a la Política de Protección de Datos</h3>
            <ul>
                <li>Actualizaciones:
                    <br>
                    <p>Descuentos Médicos App se reserva el derecho de actualizar o modificar esta política en cualquier momento para reflejar cambios en la legislación, en la tecnología o en las prácticas de la plataforma.</p>
                </li>
                <li>Notificación:
                    <br>
                    <p>Cualquier modificación será comunicada a los usuarios a través de los canales oficiales (correo electrónico, notificaciones en la plataforma, etc.) con la debida antelación.</p>
                </li>
                <li>Consentimiento:
                    <br>
                    <p>Si los cambios afectan de manera significativa el tratamiento de datos, se solicitará el consentimiento expreso del usuario antes de que las modificaciones entren en vigor.</p>
                </li>
            </ul>
          </div>
          <hr>
          <div id="section-19">
            <h3>9. Contacto y Ejercicio de Derechos</h3>
            <ul>
                <li>Para cualquier duda, solicitud o ejercicio de derechos (acceso, rectificación, cancelación, oposición), los usuarios pueden contactar a través del formulario de contacto disponible en la plataforma o mediante el correo electrónico designado para atención al usuario.</li>
            </ul>
          </div>
          <hr>
        </div>
      </section>
    </div>
  </div>
</main>
<app-landing-footer></app-landing-footer>