<div *ngIf="!groupCreated" class="card_imagen_header">
  <div class="title_header fw_500 fz_20 ml-4">{{
    groupSelected?"Actualizar Grupo Familiar":"Agregar nuevo Grupo Familiar"}}</div>
  <button type="button" id="idCerrarCitas" class="btn btn-circle btn_back" title="Cerrar"
    (click)="cerrarFamilyAdmin()"><i class="ti ti-close"></i>
  </button>

  <!-- <custom-buttons type="button" id="idCerrarCitas" class="btn btn-circle btn_back" (click)="cerrarFamilyAdmin()" text=""
    textColor="#fff" [title]="'Cerrar'" icon="ti ti-close">
    <i class="ti ti-close"></i>
  </custom-buttons> -->
</div>

<div *ngIf="!groupCreated" class="">
  <div class="card-body">
    <form [formGroup]="groupForm">

      <div class="row">

        <div class="col-12">
          <div class="form-group">
            <form-input [id]="'idInputName'" helper="Registre el nombre del grupo que desea agregar"
              label="Nombre del Grupo Familiar" placeholder="ejm: Familia Perez" [controlName]="'name'">
            </form-input>
          </div>
        </div>

        <div class="col-12">
          <label *ngIf="imageUrls.length==0" class="input__label">Imagen para el Grupo Familiar</label>
          <div class="cargar_imagen_container">
            <div *ngIf="!fileComponent && imageUrls.length==0" class="animated fadeIn">
              <app-file-image-input [inputRoundedImage]="'rounded_image_100'" [inputProcesarImagen]="true"
                [inputMaxSizeFile]="2" (uploadedImageEmmiter)="uploadedImageEmmiter($event)">
              </app-file-image-input>
            </div>

            <div *ngIf="fileComponent && imageUrls.length==0" class="animated fadeIn">
              <app-image-crop [fileComponent]="fileComponent" [type]="'Cover'" [postFile]="false" [role]="'user'"
                [minHeight]="false" [viewHeader]="true" (regresarEmitter)="regresarEmitter($event)"
                (cropperImageEmmiter)="cropperImageEmmiter($event)">
              </app-image-crop>

            </div>
          </div>

          <div class="animated fadeIn" *ngIf="imageUrls.length>0">
            <div class="p_section" style="font-size: medium;">Vista previa de la imagen cargada</div>
            <div class="subtitle_images" style="font-size: medium;">Para cambiar esta imagen, primero debe eliminarla
            </div>
            <div class="image_previews">
              <div *ngFor="let imageUrl of imageUrls;let i = index" class="image_previews_item">
                <img [src]="imageUrl" class="image_prev">
                <button class="btn btn-circle btn_back btn_back_config btn_position" type="button"
                  title="Eliminar imagen" (click)="quitarImagenLista(i)">
                  <i class="ti ti-close fw_600"></i>
                </button>

                <!-- <custom-buttons class="btn btn-circle btn_back btn_back_options btn_position" type="button"
                  (click)="quitarImagenLista(i)" text="" textColor="#fff" [title]="'Eliminar imagen'"
                  icon="ti ti-close">
                  <i class="ti ti-close fw_600"></i>
                </custom-buttons> -->
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="settings_btn mt-4">

        <custom-buttons *ngIf="!groupSelected" text="Agregar Grupo Familiar" title="Agregar Grupo" textColor="#fff"
          icon="ti-plus ml-2" type="button" (click)="agregateFamilyGroup(groupForm)" />

        <custom-buttons *ngIf="groupSelected" text="Actualizar Grupo Familiar" class="mb-4" title="Actualizar Grupo" textColor="#fff"
          icon="ti-reload ml-2" type="button" [inputBackgroundColor]="'secondary'"
          (click)="updateFamilyGroup(groupForm)" />
      </div>
    </form>

  </div>
</div>

<div *ngIf="groupCreated" class="card">
  <div class="card-body">
    <app-beneficiaries-family class="animated fadeIn" [groupSelected]="groupCreated" [options]="true">
    </app-beneficiaries-family>
  </div>
</div>
