<body>
  <section>
    <form class="form-main" [formGroup]="formVerify" (ngSubmit)="validatedCode()">
      <p>Introduce el código de 6 dígitos enviado a tu teléfono</p>
      <div class="container-inputs">
        <input #inputOneRef class="control" type="tel" formControlName="controlOne" maxlength="1"
          (input)="onInputChange($event, 0)" (keydown)="onKeyDown($event, 0)" #inputControl>
        <input class="control" type="tel" formControlName="controlTwo" maxlength="1" (input)="onInputChange($event, 1)"
          (keydown)="onKeyDown($event, 1)" #inputControl>
        <input class="control" type="tel" formControlName="controlThree" maxlength="1"
          (input)="onInputChange($event, 2)" (keydown)="onKeyDown($event, 2)" #inputControl>
        <input class="control" type="tel" formControlName="controlFour" maxlength="1" (input)="onInputChange($event, 3)"
          (keydown)="onKeyDown($event, 3)" #inputControl>

        <!-- <input class="control" type="tel" formControlName="controlFive" maxlength="1" (input)="onInputChange($event, 4)"  (keydown)="onKeyDown($event, 4)" #inputControl>
          <input class="control" type="tel" formControlName="controlSix" maxlength="1" (input)="onInputChange($event, 5)" (keydown)="onKeyDown($event, 5)"  #inputControl> -->
      </div>
      <div *ngIf="!tempState" class="container-terms">
        <input id="checkbox-signup" type="checkbox" class="chk-col-teal material-inputs" formControlName="terms">
        <label for="checkbox-signup" class="terms"> Acepto los <a href="/terms" target="_blank"> Terminos y condiciones
          </a></label>
      </div>
      <div class="container-temp animated fadeIn">
        <p class="sended animated fadeIn" *ngIf="!tempState">El código caduca en<span> {{timeOnline}}</span></p>
        <p class="resend animated fadeIn" *ngIf="tempState" (click)="resendCode()">El codigo caducó, volver a enviar
          código</p>
      </div>
      <custom-buttons class="animated fadeIn" *ngIf="!tempState"  type="submit" title="Enviar" text="Enviar" [disabled]="!formVerify.valid">Enviar</custom-buttons>
      <div class="container-validation animated fadeIn" *ngIf="formVerify.invalid && formVerify.touched && !tempState ">
        <small class="text-danger ">Por favor, completa todos los campos y acepta los términos y condiciones.</small>
      </div>
    </form>
  </section>
</body> 