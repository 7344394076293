import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { SweetAlertService } from './sweet-alert.service';
import { Store } from '@ngrx/store';
import { logout } from '../auth/state/auth.actions';

const headers = new HttpHeaders().set('Content-Type', 'application/json');
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})

/**
* Servicio para realizar peticiones HTTP (GET, POST, PUT, DELETE) a una API.
*
* Este servicio proporciona métodos para realizar diferentes tipos de solicitudes HTTP
* (GET, POST, PUT, DELETE) a una API. Además, maneja errores comunes y muestra alertas
* utilizando el servicio SweetAlertService.
*
* @param swalService - El servicio SweetAlertService utilizado para mostrar alertas.
*/
export class ApiService {


  constructor(private http: HttpClient, private swalService: SweetAlertService, private store: Store) { }

  /**
   * Realiza una solicitud GET a la API.
   *
   * @param partialEndPoint - La parte parcial de la URL de la API a la que se realizará la solicitud.
   * @param urlRequets (opcional) - La URL base de la API, si no se proporciona, se utilizará la URL predeterminada del entorno.
   * @returns Un observable que emite la respuesta de la API.
   */
  public getRequest(partialEndPoint: string, urlRequets = apiUrl): Observable<any> {
    const url = urlRequets + partialEndPoint;

    return this.http.get(url, { headers }).pipe(
      catchError((err: any) => {

        // Manejo de errores específicos
        if (err.status === 401) {
          this.swalService.lauchSwal(
            'Error',
            'La sesión ha expirado, por favor vuelve a iniciar sesión.',
            'warning'
          );
          this.store.dispatch(logout()); // Cerrar sesión
          return throwError(() => err);
        }

        if (err.status === 0) {
          this.swalService.lauchSwal(
            'Error',
            'Error en la conexión, por favor revisa tu conexión o inténtalo más tarde.',
            'error'
          );
          return throwError(() => err);
        }

        // this.swalService.lauchSwal('Error', 'Por favor revisa tu conexión o reinicia el navegador', 'error');
        return throwError(() => err);
      })
    );
  }

  /**
   * Realiza una solicitud POST a la API.
   *
   * @param partialEndPoint - La parte parcial de la URL de la API a la que se realizará la solicitud.
   * @param body - Los datos del cuerpo de la solicitud POST.
   * @param urlRequets (opcional) - La URL base de la API, si no se proporciona, se utilizará la URL predeterminada del entorno.
   * @returns Un observable que emite la respuesta de la API.
   */
  public postRequest(partialEndPoint: string, body: any, urlRequets = apiUrl): Observable<any> {
    const url = urlRequets + partialEndPoint;

    return this.http.post(url, body, { headers }).pipe(
      catchError((err: any) => {

        // Manejo de errores específicos
        if (err.status === 401) {
          this.swalService.lauchSwal(
            'Error',
            'La sesión ha expirado, por favor vuelve a iniciar sesión.',
            'warning'
          );
          this.store.dispatch(logout()); // Cerrar sesión
          return throwError(() => err);
        }

        if (err.status === 0) {
          this.swalService.lauchSwal(
            'Error',
            'Error en la conexión, por favor revisa tu conexión o inténtalo más tarde.',
            'error'
          );
          return throwError(() => err);
        }

        // this.swalService.lauchSwal('Error',  'Por favor revisa tu conexión o reinicia el navegador', 'warning');

        return throwError(() => err);
      })
    );
  }

  public postRequestFile(partialEndPoint: string, formData: FormData) {

    const url = apiUrl + partialEndPoint;

    return this.http.post(url, formData).pipe(
      catchError((err: any) => {

        // Manejo de errores específicos
        if (err.status === 401) {
          this.swalService.lauchSwal(
            'Error',
            'La sesión ha expirado, por favor vuelve a iniciar sesión.',
            'warning'
          );
          this.store.dispatch(logout()); // Cerrar sesión
          return throwError(() => err);
        }

        if (err.status === 0) {
          this.swalService.lauchSwal(
            'Error',
            'Error en la conexión, por favor revisa tu conexión o inténtalo más tarde.',
            'error'
          );
          return throwError(() => err);
        }

        this.swalService.lauchSwal('Error',  'Por favor revisa tu conexión o reinicia el navegador', 'warning');

        return throwError(() => err);
      })
    );

  }

  /**
   * Realiza una solicitud PUT a la API.
   *
   * @param partialEndPoint - La parte parcial de la URL de la API a la que se realizará la solicitud.
   * @param body - Los datos del cuerpo de la solicitud PUT.
   * @param urlRequets (opcional) - La URL base de la API, si no se proporciona, se utilizará la URL predeterminada del entorno.
   * @returns Un observable que emite la respuesta de la API.
   */
  public putRequest(partialEndPoint: string, body: any, urlRequets = apiUrl): Observable<any> {
    const url = urlRequets + partialEndPoint;

    return this.http.put(url, body, { headers }).pipe(
      catchError((err: any) => {
        

        // Manejo de errores específicos
        if (err.status === 401) {
          this.swalService.lauchSwal(
            'Error',
            'La sesión ha expirado, por favor vuelve a iniciar sesión.',
            'warning'
          );
          this.store.dispatch(logout()); // Cerrar sesión
          return throwError(() => err);
        }

        if (err.status === 0) {
          this.swalService.lauchSwal(
            'Error',
            'Error en la conexión, por favor revisa tu conexión o inténtalo más tarde.',
            'error'
          );
          return throwError(() => err);
        }

        // this.swalService.lauchSwal('Error',  'Por favor revisa tu conexión o reinicia el navegador', 'error');
        return throwError(() => err);
      })
    );
  }


  public putRequestFile(partialEndPoint: string, formData: FormData) {

    const url = apiUrl + partialEndPoint;

    return this.http.put(url, formData).pipe(
      catchError((err: any) => {

        // Manejo de errores específicos
        if (err.status === 401) {
          this.swalService.lauchSwal(
            'Error',
            'La sesión ha expirado, por favor vuelve a iniciar sesión.',
            'warning'
          );
          this.store.dispatch(logout()); // Cerrar sesión
          return throwError(() => err);
        }

        if (err.status === 0) {
          this.swalService.lauchSwal(
            'Error',
            'Error en la conexión, por favor revisa tu conexión o inténtalo más tarde.',
            'error'
          );
          return throwError(() => err);
        }

        this.swalService.lauchSwal('Error',  'Por favor revisa tu conexión o reinicia el navegador', 'warning');

        return throwError(() => err);
      })
    );

  }

  /**
   * Realiza una solicitud DELETE a la API.
   *
   * @param partialEndPoint - La parte parcial de la URL de la API a la que se realizará la solicitud.
   * @param urlRequets (opcional) - La URL base de la API, si no se proporciona, se utilizará la URL predeterminada del entorno.
   * @returns Un observable que emite la respuesta de la API.
   */
  public deleteRequest(partialEndPoint: string, urlRequets = apiUrl): Observable<any> {
    const url = urlRequets + partialEndPoint;

    return this.http.delete(url, { headers }).pipe(
      catchError((err: any) => {

        // Manejo de errores específicos
        if (err.status === 401) {
          this.swalService.lauchSwal(
            'Error',
            'La sesión ha expirado, por favor vuelve a iniciar sesión.',
            'warning'
          );
          this.store.dispatch(logout()); // Cerrar sesión
          return throwError(() => err);
        }

        if (err.status === 0) {
          this.swalService.lauchSwal(
            'Error',
            'Error en la conexión, por favor revisa tu conexión o inténtalo más tarde.',
            'error'
          );
          return throwError(() => err);
        }

        // this.swalService.lauchSwal('Error',  'Por favor revisa tu conexión o reinicia el navegador', 'error');
        return throwError(() => err);
      })
    );
  }
}
