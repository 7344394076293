<!-- ============================================================== -->
<!-- Page wrapper  -->
<!-- ============================================================== -->
<div id="home" class="page-wrapper">
  <!-- ============================================================== -->
  <!-- Container fluid  -->
  <!-- ============================================================== -->
  <div class="container_fluid">
    <!-- ============================================================== -->
    <!-- Start Page Content -->
    <!-- ============================================================== -->
    <div class="fix-width">
      <div class="row mt-7 section-frist">
        <div class="col-lg-7 col-md-7 col-sm-12 container-frist">
          <div class="text-center">
            <img class="img-logo" src="./assets/images/logo-up.png" alt="logo" />
          </div>
          <div></div>
          <h1 class="text-center text-white font-bold titulo-frist">
            ¡Tu salud es lo más 
            <span class="text-success">Importante</span>!
          </h1>
          <p class="subtext text-center text-white mt-2">
            <span class="font-medium"> ¡Disfruta de descuentos de hasta un 50% en tus servicios médicos particulares! 
            </span><br><br>
            <span>
            ¡Disfruta de descuentos de hasta un 50% en tus servicios médicos particulares!
            </span>
          </p>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-12 container_img">
          <div class="text-center animated bounceInUp">
            <div class="text-center container-img-descuentos">
              <!--
                <ng-lottie [options]="options"></ng-lottie>
              -->
              <div class="background">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
              <img src="../../../../assets/LANDING GENERATOR/landing-img-descuentos.png" alt="img-descuentos">
            </div>
          </div>
        </div>
      </div>

      <!-- form -->

      <!-- form -->
      <div class="down-btn animated fadeInDown form_container">
        <p class="label-agreement-search">Selecciona uno de nuestros convenios para agendar TU CITA</p>
        <div class="container_agreement_search">
            <div class="agreement-search" *ngFor="let image of imageUrls; let i = index">
              <a [href]="this.baseUrl + '/convenios/' + image.id">
                <img [src]="image.img" title="" class="agreement-search-img" [alt]="'Convenio ' + (i + 1)"/>
              </a>
            </div>
            <div class="container_agreement_download">
              <p class="label-agreement-search">O solicítala desde nuestra App</p>
              <custom-buttons text="Descargar App" title="Descarga la app gratis!" icon="ti-download ml-2"
              textColor="#fff" width="250px" [isLoading]="isLoaded" (click)=" scrollDonwload()" />
            </div>
        </div>
        <div>

        </div>
        <!-- <form [formGroup]="searchServiceForm" (ngSubmit)="searchSubservices()">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
              <form-input helper="" label="Especialidad, médico o afección:" [required]="false" labelColor="#fff"
                placeholder="Buscar..." [controlName]="'searchData'" [type]="'text'"></form-input>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 btn_align">
              <form-autocomplete label="¿En que ciudad quieres tu cita?" labelColor="#fff" [required]="false"
                [placeholder]="'Busca tu departamento o municipio...'" [dataList]="municipalities"
                [controlName]="'municipality'" [idField]="'_id'" [valueField]="'municipality'">
              </form-autocomplete>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 text-center btn_align mt-4 pt-md-3">

              <custom-buttons text="Busca tu cita" title="permite buscar tus servicios" textColor="#fff"
                icon="ti-search ml-2" height="42px" width="250px" [isLoading]="isLoaded" type="submit"
                class="button-search" />

            </div>
          </div>
        </form> -->
      </div>
      <!-- form -->

      <!-- form -->

      <!-- ============================================================== -->
      <!-- Doctors Section -->
      <!-- ============================================================== -->
      <!--
        <div  class="container-main-doctors" style="margin-top: 40px">
          <label class="text-white" style="font-weight: 500"
            >Profesionales destacados:</label
          >
          <div class="container_doctors">
            <div *ngFor="let doctor of doctors">
              <img [src]="doctor.img" alt="" />
              <h4>{{ doctor.name }}</h4>
              <p>{{ doctor.specialty }}</p>
            </div>
          </div>
        </div>
      -->
    </div>

    <!-- ============================================================== -->
    <!-- Featured Section -->
    <!-- ============================================================== -->

    <main class="-container-main">
      <!-- DATA NUMBERS -->
      <!-- <section class="data-number">
        <h1 class="title-data-number">
          Resumen de  <span>Actividad</span>
      </h1>

      <p class="label-data-number">Contamos con la mayor proyeccion y crecimiento en:</p>

        <div class="content-data">
              <article class="item-data">
                  <div class="icon-item">
                      <i class="icon-sizes ti-user "></i>
                  </div>
                  <div class="number-item">
                      <span>{{defaultValue}}</span>
                      <p>Usuarios</p>
                  </div>
              </article>
              <article class="item-data">
                <div class="icon-item">
                    <i class="icon-sizes  ti-pulse "></i>
                </div>
                <div class="number-item">
                    <span>304</span>
                    <p>Medicos y Especialistas</p>
                </div>
            </article>
            <article class="item-data">
              <div class="icon-item">
                  <i class="icon-sizes ti-stats-up  "></i>
              </div>
              <div class="number-item">
                  <span>1250</span>
                  <p>Servicios Medicos</p>
              </div>
          </article>
        </div>
      </section> -->
      <!-- HERO -->
      <section class="hero ">
        <div class="content-hero animated fadeIn text-center">
          <h1 class="title-hero ">
            ¿Soportarías la pérdida de un ser querido por la espera de una cita médica?
          </h1>

          <p class="text-center" style="color: white;">
            ¡No esperes más! Descarga la app, elige el servicio, el médico y el descuento que más te convenga.
          </p>

          <div class="container-buttons-hero">
            <!-- Redireccionar a la section de descargar App -->
            <custom-buttons text="Descargar App" title="Descarga la app gratis!" icon="ti-download ml-2"
              textColor="#fff" width="250px" [isLoading]="isLoaded" (click)=" scrollDonwload()" />
          </div>
        </div>
        <div class="img-hero animated fadeIn">

          <div class="background">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img class="img-logo-blanco"
            src="../../../../assets/LANDING GENERATOR/LOGO PREVENIR DESCUENTOS MEDICOS BLANCO-04.png" alt="logo">
          <img class="img-main" src="../../../../assets/LANDING GENERATOR/FOTO JEFE-18.png" alt="img-hero">
        </div>

      </section>
      <!-- DOWNLOAD -->
      <section id="app" class="download">
        <div class="video-download">
          <iframe class="video-youtube" [src]="videoUrl" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <!--
          <div class="video-download">
              <iframe *ngIf="videoVisible === true" class="video-youtube" [src]="videoUrl" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
              </iframe>
              <h2 *ngIf="buttonVisible === true" class="title-video">
                  Video Informativo
              </h2>
              <button class="button" *ngIf="buttonVisible === true" (click)="viewVideo()">
                  <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="26px">
                      <path
                          d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                          fill="#00AB78"></path>
                  </svg>
              </button>
          </div>
        -->
        <div class="content-download ">
          <h1 class="title-download text-center">
            ¿Puedo inscribir familia y mascotas en <span class="text-app">DESCUENTOS MÉDICOS App</span>?
          </h1>
          <p class="description-download text-center" style="color: white;">
            <span>¡Si! </span> Inscribe hasta <span>10 miembros </span> de tu familia y tus mascotas, para que todos
            disfruten de los
            descuentos en las citas médicas y veterinarias particulares.
          </p>
          <p class="description-download-download">¡Descarga la App DESCUENTOS MÉDICOS en tu celular e inscríbete
            GRATIS!</p>
          <div class="enlace-download">
            <a href="https://apps.apple.com/co/app/prevenir-descuentos-m%C3%A9dicos/id1560589182" target="_blank"
              title="Descargar desde Apple Store" class="btn btn-store aos-init aos-animate">
              <img src="./assets/images/stores/ios.png" style="height: 50px; width: 160px" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.prevenirdescuentosmedicos.app" target="_blank"
              title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate">
              <img src="./assets/images/stores/android.png" style="height: 50px; width: 160px" />
            </a>
            <a href="https://appgallery.cloud.huawei.com/ag/n/app/C104125813?locale=es_US&source=appshare&subsource=C104125813" target="_blank"
              title="Descargar desde Play Store" class="btn btn-store aos-init aos-animate">
              <img src="./assets/images/stores/gallery.png" style="height: 50px; width: 160px" />
            </a>
          </div>
        </div>
        <!--
            <div class="video-download-mobile">
                <iframe *ngIf="videoVisible === true" class="video-youtube" [src]="videoUrl" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
                <h2 *ngIf="buttonVisible === true" class="title-video">
                    Video Informativo
                </h2>
                <button class="button" *ngIf="buttonVisible === true" (click)="viewVideo()">
                    <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="26px">
                        <path
                            d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                            fill="#00AB78"></path>
                    </svg>
                </button>
            </div>

          -->
        <div class="video-download-mobile">
          <iframe class="video-youtube" [src]="videoUrl" frameborder="10"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
      </section>
      <!-- CARDS INFO -->
      <section class="info-med">
        <div class="go-pato">
          <h1>
            Nuestros <span>Beneficios </span>
          </h1>
          <p style="color: white;">
            Somos la mejor opción en la búsqueda de tus médicos particulares
          </p>
          <div class="container-cards">
            <article *ngFor="let card of cards" class="card">
              <img [src]="card.img" alt="">
              <div class="content-card ">
                <p class="title-card ">{{card.title}}</p>
                <p class="text-white ">{{card.description}}</p>
              </div>
            </article>
          </div>
        </div>
      </section>
       <!-- DATA NUMBERS -->
       <!--
         <section class="data-number">
           <h1 class="title-data-number">
             Resumen de  <span>Actividad</span>
         </h1>

         <p style="color: white;">Contamos con la mayor proyeccion y crecimiento en:</p>

           <div class="content-data">
                 <article class="item-data">
                     <div class="icon-item">
                         <i class="icon-sizes ti-user "></i>
                     </div>
                     <div class="number-item">
                         <span>{{defaultValue}}</span>
                         <p>Usuarios</p>
                     </div>
                 </article>
                 <article class="item-data">
                   <div class="icon-item">
                       <i class="icon-sizes  ti-pulse "></i>
                   </div>
                   <div class="number-item">
                       <span>304</span>
                       <p>Medicos y Especialistas</p>
                   </div>
               </article>
               <article class="item-data">
                 <div class="icon-item">
                     <i class="icon-sizes ti-stats-up  "></i>
                 </div>
                 <div class="number-item">
                     <span>1250</span>
                     <p>Servicios Medicos</p>
                 </div>
             </article>
           </div>
         </section>
       -->
      <!-- SLIDER TESTIMONIOS -->
      <section class="testimonios">
        <h1 class="title-testimonio">
          Experiencias de <span>Nuestros Usuarios</span>
        </h1>

        <p class="description-testimonios">Descubre cómo hemos ayudado a nuestros clientes</p>
        <div class="container-slider">
          <div id="carouselExampleIndicators" class="carousel slide w-100 h-100" data-ride="carousel">
            <div class="carousel-inner w-100 h-100" role="listbox">
              <div *ngFor="
                        let testimony of testimonials;
                        let i = index
                      " class="carousel-item  carousel_item_style w-100 h-100" [ngClass]="{ active: i === 0 }">
                <!-- CARD -->
                <article class="card-testimonio">

                  <div class="container-top">
                    <div class="container-img">
                      <img [src]="testimony.img" [alt]="'Slide ' + (i + 1)">
                    </div>
                    <div class="container-text-testimonio">
                      <p class="name-testimonio">{{testimony.name}}</p>
                      <p class="occupation-testimonio">{{testimony.occupation}}</p>
                    </div>
                    <div class="container-sticker">
                      <img class="comillas" src="../../../../assets/LANDING GENERATOR/icons8-comillas-96.png" alt="">
                    </div>
                  </div>

                  <div class="container-content-testimonio">
                    <p class="testimony-testimonio">{{testimony.testimony}}</p>
                  </div>
                </article>


              </div>
            </div>
            <!-- Button prev -->
            <a class="carousel-control-prev button-prev" href="#carouselExampleIndicators" role="button"
              data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <!-- Button next -->
            <a class="carousel-control-next button-next" href="#carouselExampleIndicators" role="button"
              data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </section>
      <!-- FREQUENT QUESTIONS -->
      <section id="faq" class="frecuentes">
        <h1 class="title-frecuentes">Preguntas <span>Frecuentes</span></h1>
        <div class="accordion">
          <div class="accordion-content" *ngFor="let item of items; let i = index">
            <header (click)="toggleItem(i)">
              <span class="title">{{ item.title }}</span>
              <i class="fa" [class]="item.isOpen ? 'fa-solid fa-minus' : 'fa-solid fa-plus'"></i>
            </header>
            <p class="description" [class.open]="item.isOpen">
              {{ item.description }}
            </p>
          </div>
        </div>
      </section>
      <!-- INTEREST -->
      <section class="interes">
        <div class="img-doctor">
          <div class="background">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src="../../../../assets/LANDING GENERATOR/doctor-ficti-2.png" alt="">
        </div>
        <div class="content-interes">
          <h1 class="title-interes ">¿Eres Profesional de la <span>Salud</span>?</h1>

          <p class="text-thr-interes" style="color: white;">Agenda una cita virtual y te explicaremos cómo pertenecer a
            nuestro selecto grupo de médicos y profesionales de la salud para atender a nuestros usuarios, aumentar el
            flujo de pacientes particulares en tu consultorio, formas de pago de la consulta, funciones de la agenda
            virtual y que tu marca aparezca en nuestra App</p>
          <div class="w-100">
            <div class="info-nos">
                <custom-buttons
                title="Hablar con un asesor"
                icon="fa fa-whatsapp ml-2"
                fontSizeIcon="1.2rem"
                text="Comunícate con nosotros" (click)="abrirWhatsApp()">
                </custom-buttons>

            </div>
          </div>
        </div>
      </section>
      <!-- CARDS INFO -->
<!-- 
      <section class="convenio-section">
        <h1 class="convenio-title">
          Nuestros <span>Convenios</span>
        </h1>

        <p class="description-testimonios mt-3 mb-3">Descubre cómo hemos ayudado a nuestros clientes</p>

        <div class="convenio-content">
          <div class="convenio-row">
            <div class="convenio-column" *ngFor="let image of imageUrls; let i = index" >
              <img [src]="image.img" class="convenio-image" [alt]="'Convenio ' + (i + 1)" (click)="redirectAgreement()"/>
            </div>
          </div>
        </div>
      </section> -->



      <!--TODO: queda pendiente la section del equipo de trabajo -->
      <!--BUTTON FIXED WP -->
      <!--

        <footer>
            <a href="https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20comunico%20desde%20la%20web%20quiero%20saber%20m%C3%A1s%20de%20Prevenir%20Descuentos%20M%C3%A9dicos!"
                class="btn-wsp" target="_blank">
                <i style="font-size: 35px; margin-top:12px;" class="fa fa-whatsapp icono"></i>
            </a>
        </footer>
      -->
    </main>




    <!-- ============================================================== -->
    <!-- Back up button -->
    <!-- ============================================================== -->

    <button class="bt-top btn btn-circle btn-lg btn-success" type="button" (click)="volverArriba()">
      <i class="font-bold ti-arrow-up"></i>
    </button>

    <!-- <custom-buttons class="bt-top" type="button" (click)="volverArriba()" text="" textColor="#fff" [title]="''"
      icon="ti-arrow-up" style="font-weight: bold; font-size: 30px;" [rounded]="true">
    </custom-buttons> -->
  </div>
  <!-- ============================================================== -->
  <!-- End Container fluid  -->
  <!-- ============================================================== -->
</div>
