<span class="input__group">
  <div class="box__label">
    <span class="input__label" [style.color]="labelColor" [style.fontSize]="labelFontSize">
      {{ label }} <strong *ngIf="required"> *</strong>
    </span>
    <i class="fa fa-question icon__helper" [title]="helper" *ngIf="helper"></i>
  </div>

  <div class="box__input" id="radio-group">
    <div class="radios__container" [class.bordered]="bordered" [ngClass]="{invalid: control.touched && control.invalid}">
      <span class="radio__item" *ngFor="let radio of data">
        <!-- Ocultamos el input real pero seguimos vinculándolo al control -->
        <input
          type="radio"
          [formControl]="control"
          [id]="'rad_' + radio.value"
          [value]="radio.value"
          name="{{ controlName }}"
        />
        <!-- Label que usará los estilos personalizados -->
        <label class="ellipsis__text" [style.color]="labelColor" [for]="'rad_' + radio.value" [title]="radio.label">
          {{ radio.label }}
        </label>
      </span>
    </div>
  </div>

  <div class="box__error" *ngIf="control.invalid && control.touched">
    <ul>
      <li class="box__error__item"><strong>* </strong>{{ getErrorMessage() }}</li>
    </ul>
  </div>
</span>

