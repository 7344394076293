import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'custom-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ButtonsComponent implements OnInit {

  @Input() text: string = '';
  @Input() textSize: string = '1em';
  @Input({required: true}) title: string = 'Default';
  @Input() width: string = '';
  @Input() height: string = 'auto';
  @Input() textColor: string = '#ffffff';
  @Input() inputBackgroundColor: string = 'primary';
  @Input() isLoading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() icon?: string;
  @Input() fontSizeIcon?: string = '0.95em'
  @Input() type: string = "";
  @Input() rounded: boolean = false;
  @Input() hostColor: string = '#ffffff';

  backgroundColor: string = '#00ab78';


  ngOnInit(): void {
    if (this.inputBackgroundColor === 'primary') {
      this.backgroundColor = '#00ab78';
    }
    else if (this.inputBackgroundColor === 'secondary') {
      this.backgroundColor = '#058dd9';
    }
    else if (this.inputBackgroundColor === 'danger') {
      this.backgroundColor = '#e74d3ce6';
    }
    else if (this.inputBackgroundColor === 'warning') {
      this.backgroundColor = '#c39700';
    }
    else if (this.inputBackgroundColor === 'gray') {
      this.backgroundColor = 'var(--background_2)';
    }
  }
}
