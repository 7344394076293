import { Injectable } from '@angular/core';
import { ApiRes } from '@interfaces/information.interface';
import { ApiService } from '@services';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {

  constructor(private apiService: ApiService) { }


  getAgreement(idAgreement: string) {
    return this.apiService.getRequest(`agreement/url/${idAgreement}`).pipe(
      map((res: ApiRes) => {
        return res.body;
      })
    )
  }
}
