import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'form-radios',
  templateUrl: './form-radios.component.html',
  styleUrls: ['../../form-styles.scss', './form-radios.component.scss']
})
export class FormRadiosComponent {
  @Input({ required: true }) controlName!: string;
  @Input({ required: true }) data: { label: string; value: any }[] = [];
  @Input() label: string = '';
  @Input() required: boolean = true;
  @Input() labelColor!: string;
  @Input() labelFontSize: string = '1rem';
  @Input() helper?: string;
  @Input() bordered: boolean = true;

  constructor(private controlContainer: ControlContainer) {}

  

  get control(): FormControl {
    const control = this.controlContainer?.control?.get(this.controlName) as FormControl;

    if (this.required && control && !control.hasValidator(Validators.required)) {
      control.addValidators(Validators.required);
      control.updateValueAndValidity();
    }

    return control;
  }

  getErrorMessage(): string {
    if (this.control?.hasError('required')) {
      return 'Este campo es obligatorio.';
    }
    return '';
  }
}
